import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner";
import { useQuery } from "@apollo/client";
import { DASHBOARD_REPORT_OBJECT_COUNT } from "../query";
import TableLoader from "../../components/Reusable/Loader/TableLoader";
import SalesLoaderLayout from "../../components/Reusable/Loader/SalesLoaderLayout";
type Props = {};
const SalesReportComponent: any = Loadable({
  loader: () => import("../../components/Reports/SalesReport/SalesReport"),
  loading: () => <SalesLoaderLayout />,
});
const SalesReport = (props: Props) => {
  const { data: salesData } = useQuery(DASHBOARD_REPORT_OBJECT_COUNT);

  return (
    <>
      <SalesReportComponent salesData={salesData} />
    </>
  );
};

export default SalesReport;
