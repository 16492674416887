import { gql } from "@apollo/client";

export const QUERY_SETTINGS = gql`
  query QUERY_SETTINGS {
    basicWebsiteConfigurations {
      id
      pk
      title
      timezone
      currency
      isStockMaintain
      printProductByCategoryOrder
      serviceCharge {
        title
        pk
      }
      country
      logo
      isAutoPrint
      isProductWisePrint
      noOfColumns
    }
  }
`;

export const QUERY_TRANSACTION_ACCOUNTS = gql`
  query ($first: Int, $offset: Int, $isVisible: Boolean) {
    transactionAccounts(first: $first, offset: $offset, isVisible: $isVisible) {
      edges {
        node {
          id
          pk
          name
          slug
          isDefault
          isRequiredTender
          transactionCost
        }
      }
    }
  }
`;

export const DASHBOARD_REPORT_OBJECT_COUNT = gql`
  query DashboardReportObjectCount {
    dashboardReportObjectCount {
      todaySaleCount
      todaySaleIncrement
      todayProductSaleCount
      todayProductSaleIncrement
      todayModifierSaleCount
      todayModifierSaleIncrement
      todayCustomerCount
      todayCustomerIncrement
      overAllSaleCount
      overAllSaleAmount
      overAllProductCount
      overAllProductAmount
      overAllModifierCount
      overAllModifierAmount
      overAllSaleAverageCount
      overAllSaleAverageAmount
    }
  }
`;

export const DASHBOARD_SALES_GRAPH = gql`
  query DASHBOARD_SALES_GRAPH($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardSalesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      salesCount
      salesAmount
      productsCount
      productsAmount
      graphData {
        label
        salesCount
        salesAmount
        productsCount
        productsAmount
      }
    }
  }
`;

export const DASHBOARD_ORDER_GRAPH = gql`
  query DASHBOARD_ORDER_GRAPH($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardOrderGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      totalOrderAmount
      totalOrderCount
      graphData {
        label
        count
        amount
      }
    }
  }
`;
export const DASHBOARD_EXPENSES_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardExpensesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      refundOrderCount
      refundOrderAmount
      voidOrderCount
      voidOrderAmount
      discountOrderCount
      discountOrderAmount
      rewardProgramOrderCount
      rewardProgramOrderAmount
      graphData {
        label
        discountCount
        discountAmount
        refundCount
        refundAmount
        voidCount
        voidAmount
        rewardProgramCount
        rewardProgramAmount
      }
    }
  }
`;

export const HOURLY_SALES_REPORT = gql`
  query HOURLY_SALES_REPORT($fromDate: Date, $toDate: Date) {
    hourlyTransactionHistory(fromDate: $fromDate, toDate: $toDate) {
      date {
        name
        count
        amount
      }
      fromDate
      toDate
      createdBy
      createdOn
    }
  }
`;
export const DETAIL_SALES_REPORT = gql`
  query DETAIL_SALES_REPORT(
    $user: [String]
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $table: [String]
    $paymentMethods: [String]
    $isTaxable: Boolean
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    detailSaleReport(
      user: $user
      category: $category
      item: $items
      table: $table
      paymentMethods: $paymentMethods
      isTaxable: $isTaxable
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      createdBy
      createdOn
      grossSale
      refunds {
        count
        amount
      }
      discounts {
        count
        amount
      }
      cashOut {
        count
        amount
      }
      rewardsReedem {
        count
        amount
      }
      accountTransaction {
        name
        amount
        count
      }
      staffPerformance {
        name
        amount
        count
      }
      orderStatistics {
        name
        amount
        count
      }
      totalTransaction
      netSales
      gst
      surCharge
      netBalance
      serCharge
      eftposCharge
      gstSale
      gstFreeSale
      cashBalance
      totalCashOut
      totalOrder
      floatAmount
    }
  }
`;
export const QUICK_SALE_REPORT = gql`
  query QUICK_SALES_REPORT(
    $paymentMethods: [String]
    $taxType: String!
    $fromDateTime: DateTime!
    $toDateTime: DateTime!
  ) {
    quickSaleReport(
      paymentMethods: $paymentMethods
      taxType: $taxType
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      modeOfPayment {
        name
        price
        quantity
      }
      gstItem
      gstFreeItem
      totalSales
      totalAmountInTill
      float
      createdBy
      createdOn
    }
  }
`;

export const PRODUCTWISE_REPORT = gql`
  query PRODUCTWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
    $query: String
  ) {
    detailProductWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
      query: $query
    ) {
      product
      quantity
      totalCost
      totalSales
      profitLoss
    }
  }
`;

export const USERWISE_REPORT = gql`
  query USERWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailUserWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalCost
      totalTransaction
    }
  }
`;
export const CATEGORYWISE_REPORT = gql`
  query CATEGORYWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailCategoriesWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      category
      quantity
      totalCost
      totalSales
      profitLoss
    }
  }
`;

export const FLOORWISE_REPORT = gql`
  query FLOORWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailFloorWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      tableCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;

export const TABLEWISE_REPORT = gql`
  query TABLEWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailTableWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;
export const POS_SCREEN_PRODUCT_CATEGORY = gql`
  query QUERY_CATEGORY($first: Int, $level: Int, $status: String) {
    categories(first: $first, level: $level, status: $status) {
      edges {
        node {
          id
          pk
          name
          status
          parent {
            id
            pk
            name
          }
          children(first: 100) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_USERS = gql`
  query QUERY_USERS(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $email_Icontains: String
    $isStaff: Boolean
    $isActive: Boolean
  ) {
    users(
      first: $first
      after: $after
      before: $before
      offset: $offset
      email_Icontains: $email_Icontains
      isStaff: $isStaff
      isActive: $isActive
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          fullName
          username
          attendancePin
          pk
          email
          mobileNumber
          isStaff
          isSuperuser
          isActive
          isCustomer
          groups {
            edges {
              node {
                name
              }
            }
          }
          fullName
        }
      }
    }
  }
`;
export const POS_SCREEN_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isModifier: Boolean
    $compound_Isnull: Boolean
    $isOpenProduct: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      search: $search
      category: $categoryList
      isModifier: $isModifier
      compound_Isnull: $compound_Isnull
      isOpenProduct: $isOpenProduct
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          plu
          isModifier
          productStocks {
            availableQuantity
          }
          barcodeContent
          isActive
          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_RESTAURANT_TABLE = gql`
  query QUERY_RESTAURANT_TABLE(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
    $subTable_Isnull: Boolean
    $isVisible: Boolean
  ) {
    restaurantTables(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
      subTable_Isnull: $subTable_Isnull
      isVisible: $isVisible
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          capacity
          floor {
            title
          }
          status
          note
        }
      }
    }
  }
`;

export const QUERY_TRANSACTIONS_VIEW = gql`
  query transactions(
    $first: Int
    $offset: Int
    $before: String
    $after: String
    $account: ID
    $created_Gte: DateTime
    $created_Lte: DateTime
    $account_IsVisible: Boolean
  ) {
    transactions(
      first: $first
      after: $after
      before: $before
      offset: $offset
      account: $account
      created_Gte: $created_Gte
      created_Lte: $created_Lte
      account_IsVisible: $account_IsVisible
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pk
          id
          pk
          created
          debit
          createdBy {
            fullName
            username
          }
          credit
          note
          terminal
          account {
            id
            pk
            name
          }
          user {
            id
            pk
            fullName
          }
          order {
            id
            pk
            getInvoiceNumber
            paymentStatus
            totalPrice
            grossAmount
            kitchenOrder {
              getInvoiceNumber
              receiptId
            }
            orderMethod {
              id
              title
            }
            customer {
              id
              pk
            }
          }
        }
      }
    }
  }
`;
export const ACCOUNT_TRANSACTION = gql`
  query ACCOUNT($slug: String) {
    transactionAccounts(slug: $slug) {
      edges {
        node {
          accountBalance
          name
          id
          pk
        }
      }
    }
  }
`;
export const QUERY_CUSTOMER = gql`
  query CUSTOMERS($first: Int, $offset: Int, $search: String) {
    customers(first: $first, offset: $offset, search: $search) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          outstandingBalance
          todayCredit
          user {
            id
            pk
            email
            mobileNumber
            fullName
          }
        }
      }
    }
  }
`;

export const QUERY_KITCHEN_ORDER = gql`
  query KITCHEN_ORDER(
    $orderStatus: String
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $isBumped: Boolean
  ) {
    kitchenOrders(
      orderStatus: $orderStatus
      first: $first
      after: $after
      before: $before
      offset: $offset
      isBumped: $isBumped
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          orderStatus
          totalItems
          totalPrice
          getInvoiceNumber
          customer {
            pk
            id
            user {
              pk
              id
              fullName
            }
          }
          orderMethod {
            title
          }
          kitchenOrderItems {
            pk
            id
            product {
              finalPrice
              taxAmount
            }
            quantity
          }
        }
      }
    }
  }
`;

export const QUERY_ORDERS = gql`
  query Orders(
    $first: Int
    $offset: Int
    $search: String
    $orderSource: String
    $customerId: ID
  ) {
    orders(
      first: $first
      offset: $offset
      search: $search
      orderSource: $orderSource
      customer: $customerId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          created
          kitchenOrder {
            id
            pk
            getInvoiceNumber
          }
          orderMethod {
            title
          }
          paymentStatus
          totalPrice
          grossAmount
          orderStatus
        }
      }
    }
  }
`;

export const QUERY_ORDERED_ITEMS = gql`
  query ORDERED_ITEMS($id: ID!, $first: Int, $offset: Int) {
    order(id: $id) {
      id
      pk
      getInvoiceNumber
      accountFee
      rewardPoint
      authorizedBy
      created
      note
      terminal
      reason
      orderMethod {
        title
      }
      customer {
        id
        pk
        user {
          id
          pk
          username
          isStaff
          mobileNumber
          fullName
        }
      }
      table {
        id
        pk
        title
      }
      kitchenOrder {
        id
        pk
        getInvoiceNumber
      }
      createdBy {
        id
        pk
        username
        fullName
      }
      note
      totalPrice
      discount
      surCharge
      tax
      serviceCharge
      grossAmount
      paymentStatus
      paymentMethod
      orderSource
      orderStatus
      changeAmount
      paidAmount
      orderTransactions {
        edgeCount
        totalCount
      }
      orderMethod {
        id
        title
      }
      orderItems(first: $first, offset: $offset) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            pk
            quantity
            discount
            surCharge
            totalPrice
            grossAmount
            price
            title
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
              taxAmount
              tax {
                id
                pk
                rate
                isIncluded
              }
            }
            modifier {
              id
              pk
              title
              quantity
              totalPrice
              discount
              product {
                id
                pk
                finalPrice
                sellingPrice
                costPrice
                taxAmount
                tax {
                  id
                  pk
                  rate
                  isIncluded
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_KITCHEN_ORDER_ITEMS = gql`
  query kitchenOrder($id: ID!) {
    kitchenOrder(id: $id) {
      id
      pk
      getInvoiceNumber
      orderStatus
      orderMethod {
        pk
        title
      }
      note
      createdBy {
        username
        fullName
      }
      customer {
        pk
        id
        user {
          id
          pk
          mobileNumber
          email
          fullName
          profile {
            fullAddress
          }
        }
      }
      table {
        pk
        id
        status
        title
        currentKitchenOrder {
          id
        }
      }
      totalPrice
      totalItems
      kitchenOrderItems {
        id
        pk
        quantity
        taxAmount
        enteredAmount
        note
        modifiers {
          edges {
            node {
              id
              pk
              quantity
              note
              product {
                id
                pk
                title
                taxAmount
                tax {
                  rate
                  isIncluded
                }
                finalPrice
                sellingPrice
              }
            }
          }
        }
        product {
          id
          pk
          title
          taxAmount
          tax {
            rate
            isIncluded
          }
          finalPrice
          sellingPrice
          compound {
            id
            pk
            sellingPrice
            title
          }
        }
      }
    }
  }
`;

