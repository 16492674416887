import React from "react";

const HourlySalesSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.13937 2.82997C7.7764 -0.150584 13.1514 0.0568232 16.5467 3.4522C20.1625 7.06794 20.1625 12.9309 16.5467 16.5466C12.9311 20.1624 7.06807 20.1624 3.45233 16.5466C2.34272 15.4385 1.53296 14.0666 1.09905 12.5598C0.665148 11.0528 0.621351 9.46032 0.971774 7.93183L1.04307 7.64201L2.83381 8.11423C2.38987 9.80151 2.55575 11.5913 3.30221 13.1683C4.04868 14.7452 5.32782 16.0081 6.91421 16.7343C8.5006 17.4605 10.2924 17.6034 11.9738 17.1379C13.6553 16.6724 15.1185 15.6283 16.1055 14.1896C17.0925 12.751 17.5401 11.0101 17.3692 9.27373C17.1984 7.53741 16.4202 5.91712 15.1716 4.69841C13.9232 3.47968 12.2846 2.74077 10.5446 2.61188C8.80469 2.48299 7.07509 2.97241 5.66067 3.99386L5.45603 4.14756L6.3977 5.08923L2.14215 6.07072L3.12362 1.81516L4.13937 2.82997ZM10.9255 4.44386V6.29572H13.2403V8.14756H8.1477C8.03201 8.14735 7.92043 8.19046 7.83493 8.26841C7.74944 8.34635 7.69623 8.45347 7.68578 8.5687C7.67532 8.68392 7.70839 8.79887 7.77846 8.89093C7.84852 8.98298 7.95052 9.04547 8.06437 9.06608L8.1477 9.0735H11.8514C12.4653 9.0735 13.0542 9.31737 13.4883 9.75148C13.9224 10.1856 14.1662 10.7744 14.1662 11.3883C14.1662 12.0022 13.9224 12.591 13.4883 13.0251C13.0542 13.4592 12.4653 13.7031 11.8514 13.7031H10.9255V15.555H9.07362V13.7031H6.75881V11.8513H11.8514C11.9671 11.8515 12.0787 11.8084 12.1642 11.7304C12.2496 11.6525 12.3028 11.5453 12.3133 11.4301C12.3237 11.315 12.2907 11.2 12.2206 11.1079C12.1506 11.0159 12.0486 10.9534 11.9347 10.9328L11.8514 10.9253H8.1477C7.53377 10.9253 6.94499 10.6815 6.51088 10.2474C6.07677 9.81324 5.83289 9.22445 5.83289 8.61053C5.83289 7.9966 6.07677 7.40782 6.51088 6.97371C6.94499 6.5396 7.53377 6.29572 8.1477 6.29572H9.07362V4.44386H10.9255Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.13937 2.82997C7.7764 -0.150584 13.1514 0.0568232 16.5467 3.4522C20.1625 7.06794 20.1625 12.9309 16.5467 16.5466C12.9311 20.1624 7.06807 20.1624 3.45233 16.5466C2.34272 15.4385 1.53296 14.0666 1.09905 12.5598C0.665148 11.0528 0.621351 9.46032 0.971774 7.93183L1.04307 7.64201L2.83381 8.11423C2.38987 9.80151 2.55575 11.5913 3.30221 13.1683C4.04868 14.7452 5.32782 16.0081 6.91421 16.7343C8.5006 17.4605 10.2924 17.6034 11.9738 17.1379C13.6553 16.6724 15.1185 15.6283 16.1055 14.1896C17.0925 12.751 17.5401 11.0101 17.3692 9.27373C17.1984 7.53741 16.4202 5.91712 15.1716 4.69841C13.9232 3.47968 12.2846 2.74077 10.5446 2.61188C8.80469 2.48299 7.07509 2.97241 5.66067 3.99386L5.45603 4.14756L6.3977 5.08923L2.14215 6.07072L3.12362 1.81516L4.13937 2.82997ZM10.9255 4.44386V6.29572H13.2403V8.14756H8.1477C8.03201 8.14735 7.92043 8.19046 7.83493 8.26841C7.74944 8.34635 7.69623 8.45347 7.68578 8.5687C7.67532 8.68392 7.70839 8.79887 7.77846 8.89093C7.84852 8.98298 7.95052 9.04547 8.06437 9.06608L8.1477 9.0735H11.8514C12.4653 9.0735 13.0542 9.31737 13.4883 9.75148C13.9224 10.1856 14.1662 10.7744 14.1662 11.3883C14.1662 12.0022 13.9224 12.591 13.4883 13.0251C13.0542 13.4592 12.4653 13.7031 11.8514 13.7031H10.9255V15.555H9.07362V13.7031H6.75881V11.8513H11.8514C11.9671 11.8515 12.0787 11.8084 12.1642 11.7304C12.2496 11.6525 12.3028 11.5453 12.3133 11.4301C12.3237 11.315 12.2907 11.2 12.2206 11.1079C12.1506 11.0159 12.0486 10.9534 11.9347 10.9328L11.8514 10.9253H8.1477C7.53377 10.9253 6.94499 10.6815 6.51088 10.2474C6.07677 9.81324 5.83289 9.22445 5.83289 8.61053C5.83289 7.9966 6.07677 7.40782 6.51088 6.97371C6.94499 6.5396 7.53377 6.29572 8.1477 6.29572H9.07362V4.44386H10.9255Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default HourlySalesSVG;
