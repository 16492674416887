import DashboardSVG from "../assets/svgComponent/DashboardSVG";
import AttendenceSVG from "../assets/svgComponent/AttendenceSVG";

import UserManagementIcon from "../assets/icon/user.svg";
import UserSVG from "../assets/svgComponent/UserSVG";

import OrderIcon from "../assets/icon/orders.svg";
import OrderSVG from "../assets/svgComponent/Orders/OrderSVG";
import HoldCartSVG from "../assets/svgComponent/Orders/HoldCartSVG";
import CustomerSVG from "../assets/svgComponent/Orders/CustomerSVG";
import RemoteOrderSVG from "../assets/svgComponent/Orders/RemoteOrderSVG";
import TransactionsSVG from "../assets/svgComponent/Orders/TransactionsSVG";

import ReportIcon from "../assets/icon/report.svg";
import SalesReportSVG from "../assets/svgComponent/Report/SalesReportSVG";
import DetailsSalesSVG from "../assets/svgComponent/Report/DetailsSalesSVG";
import HourlySalesSVG from "../assets/svgComponent/Report/HourlySalesSVG";
import QuickSalesSVG from "../assets/svgComponent/Report/QuickSalesSVG";

import ExpensesIcon from "../assets/icon/report.svg";
import RefundSVG from "../assets/svgComponent/Report/SalesReportSVG";
import CashOutSVG from "../assets/svgComponent/Report/DetailsSalesSVG";
import VoidReportSVG from "../assets/svgComponent/Report/HourlySalesSVG";
import DiscountReportSVG from "../assets/svgComponent/Report/QuickSalesSVG";
import RewardsProgramReportSVG from "../assets/svgComponent/Report/QuickSalesSVG";

import SettingIcon from "../assets/icon/setting.svg";
import SettingSVG from "../assets/svgComponent/SettingSVG";

const routerObject = [
  { name: "DashBoard", icon: DashboardSVG, path: "/", children: [] },
  {
    name: "Attendance",
    icon: AttendenceSVG,
    path: "/attendance",
    children: [],
  },
  {
    name: "User Management",
    icon: UserManagementIcon,
    path: null,
    children: [
      {
        name: "Active Users",
        icon: UserSVG,
        path: "/active-users",
        children: [],
      },
      {
        name: "In Active Users",
        icon: UserSVG,
        path: "/inactive-users",
        children: [],
      },
    ],
  },
  {
    name: "Orders",
    icon: OrderIcon,
    path: null,
    children: [
      {
        name: "Orders",
        icon: OrderSVG,
        path: "/orders",
        children: [],
      },
      {
        name: "Hold Cart",
        icon: HoldCartSVG,
        path: "/hold-cart",
        children: [],
      },
      {
        name: "Customer",
        icon: CustomerSVG,
        path: "/customer",
        children: [],
      },
      {
        name: "Remote Order",
        icon: RemoteOrderSVG,
        path: "/remote-order",
        children: [],
      },
      {
        name: "Transactions",
        icon: TransactionsSVG,
        path: "/transactions",
        children: [],
      },
    ],
  },

  {
    name: "Report",
    icon: ReportIcon,
    path: null,
    children: [
      {
        name: "Sales Report",
        icon: SalesReportSVG,
        path: "/sales-report",
        children: [],
      },
      {
        name: "Details Sales Report",
        icon: DetailsSalesSVG,
        path: "/detail-sales-report",
        children: [],
      },
      {
        name: "Hourly Sales Report",
        icon: HourlySalesSVG,
        path: "/hourly-sales-report",
        children: [],
      },
      {
        name: "Quick Sales Report",
        icon: QuickSalesSVG,
        path: "/quick-sales-report",
        children: [],
      },
      {
        name: "Most Selling Products Report",
        icon: QuickSalesSVG,
        path: "/productwise-report",
        children: [],
      },
      {
        name: "Most Selling Categories Report",
        icon: QuickSalesSVG,
        path: "/categorywise-report",
        children: [],
      },
      {
        name: "Sales By User",
        icon: QuickSalesSVG,
        path: "/userwise-report",
        children: [],
      },
      {
        name: "Sales By Floor",
        icon: QuickSalesSVG,
        path: "/floorwise-report",
        children: [],
      },
      {
        name: "Sales By Table",
        icon: QuickSalesSVG,
        path: "/tablewise-report",
        children: [],
      },
    ],
  },

  {
    name: "Expenses Report",
    icon: ExpensesIcon,
    path: null,
    children: [
      {
        name: "Refund",
        icon: RefundSVG,
        path: "/refund",
        children: [],
      },
      {
        name: "CashOut",
        icon: CashOutSVG,
        path: "/cashout",
        children: [],
      },
      {
        name: "Void Order/Company Expenses",
        icon: VoidReportSVG,
        path: "/void-order-expenses",
        children: [],
      },
      {
        name: "Discount",
        icon: DiscountReportSVG,
        path: "/discount-expenses",
        children: [],
      },
      {
        name: "RewardsProgram",
        icon: RewardsProgramReportSVG,
        path: "/reward-programs",
        children: [],
      },
    ],
  },
  {
    name: "Setting",
    icon: SettingIcon,
    path: null,
    children: [
      {
        name: "Sync Info",
        icon: SettingSVG,
        path: "/sync-info",
        children: [],
      },
    ],
  },
];

export default routerObject;
