import React from "react";
import Loadable from "react-loadable";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { QUERY_CUSTOMER } from "../../query";
import { useQuery, useReactiveVar } from "@apollo/client";
import { searchItemsVar } from "../../../components/LocalState/Cache";

const CustomerComponent: any = Loadable({
  loader: () => import("../../../components/Order/Customer/Customer"),
  loading: () => <TableLoader />,
});
type Props = {};
const Customer = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: customer, loading } = useQuery(QUERY_CUSTOMER, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
  });
  const data = customer?.customers?.edges ?? [];
  const pageInfo = customer?.customers?.pageInfo ?? {};
  return (
    <div>
      <CustomerComponent
        data={data}
        pageInfo={pageInfo}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
        loading={loading}
      />
    </div>
  );
};

export default Customer;
