import Loadable from "react-loadable";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { useQuery, useReactiveVar } from "@apollo/client";
import { QUERY_ORDERS } from "../../query";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";
import { searchItemsVar } from "../../../components/LocalState/Cache";
import { debounce } from "lodash";

const OrderComponent: any = Loadable({
  loader: () => import("../../../components/Order/Orders/Orders"),
  loading: () => <TableLoader />,
});
type Props = {};
const Orders = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data: orders, loading } = useQuery(QUERY_ORDERS, {
    variables: {
      first: pageSize,
      offset: offset,
      search: searchItems?.searchInput,
    },
  });

  const data = orders?.orders?.edges ?? [];
  const pageInfo = orders?.orders?.pageInfo ?? {};
  return (
    <>
      <OrderComponent
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data}
        loading={loading}
        pageInfo={pageInfo}
      />
    </>
  );
};

export default Orders;
