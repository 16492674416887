// import React from "react";
// import Loadable from "react-loadable";
// import DataLoadSpinner from "../../components/Reusable/Spinner";
// import { useQuery } from "@apollo/client";
// import { QUERY_ACTIVATION_INFO } from "../query";
// type Props = {};
// const AboutComponent: any = Loadable({
//   loader: () => import("../../components/About/About"),
//   loading: DataLoadSpinner,
// });

// const About = (props: Props) => {
//   const { data: activationInfo, loading } = useQuery(QUERY_ACTIVATION_INFO);

//   return (
//     <>
//       <AboutComponent activationInfo={activationInfo?.activationInfo} />
//     </>
//   );
// };

// export default About;
import React from 'react'

const About = () => {
  return (
    <div>About</div>
  )
}

export default About