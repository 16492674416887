import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import moment from "moment";
import { ACCOUNT_TRANSACTION, QUERY_TRANSACTIONS_VIEW } from "../../query";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { selectedDatePickerItemVar } from "../../../components/LocalState/Cache";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";
type Props = {};
const CashoutExpensesComponent: any = Loadable({
  loader: () => import("../../../components/ExpensesReport/Cashout"),
  loading: () => <TableLoader />,
});
const CashoutExpenses = (props: Props) => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { startDate, endDate }: any = useReactiveVar(selectedDatePickerItemVar);

  const [getDiscountData, { data: discountData }] =
    useLazyQuery(ACCOUNT_TRANSACTION);

  const {
    data: TransactionData,
    refetch: refetchTransactionData,
    loading,
  } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: {
      first: pageSize,
      offset: offset,
      created_Gte: startDate
        ? moment(startDate).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      created_Lte: endDate
        ? moment(endDate).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : null,
      account: discountData?.transactionAccounts?.edges[0].node?.id,
      isVisible: false,
    },
    skip: !discountData,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDiscountData({
          variables: {
            slug: "cash-out",
          },
        });
      } catch (error) {
        console.error("Error fetching discount data:", error);
      }
    };
    fetchData();
  }, [getDiscountData, refetchTransactionData]);
  const data = TransactionData?.transactions?.edges ?? [];
  const pageInfo = TransactionData?.transactions?.pageInfo ?? {};
  return (
    <div>
      <CashoutExpensesComponent
        data={data}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
        pageInfo={pageInfo}
        loading={loading}
      />
    </div>
  );
};

export default CashoutExpenses;
