import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const OrderLoader = (props: Props) => {
  return (
    <div className="relative rounded bg-white shadow mt-4 p-4">
      <div className="flex justify-between ">
        <h2 className="mb-4">
          <Skeleton height={30} width={60} />
        </h2>
        <div className="flex justify-between gap-4">
          <Skeleton height={30} width={60} />
          <Skeleton height={30} width={60} />
        </div>
      </div>
      <div className="h-72 grid grid-cols-2 gap-6 ">
        <div className="my-auto">
          <Skeleton circle width={200} height={200} />
        </div>
        <div className="w-full pl-5 overflow-auto">
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3 m-2 flex justify-center items-center text-white rounded-lg">
              <Skeleton height={50} width={50} />
            </div>
            <div className="w-2/3 flex flex-col ">
              <Skeleton height={25} width={70} />
              <Skeleton height={25} width={70} />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3 m-2 flex justify-center items-center text-white rounded-lg">
              <Skeleton height={50} width={50} />
            </div>
            <div className="w-2/3 flex flex-col ">
              <Skeleton height={25} width={70} />
              <Skeleton height={25} width={70} />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3 m-2 flex justify-center items-center text-white rounded-lg">
              <Skeleton height={50} width={50} />
            </div>
            <div className="w-2/3 flex flex-col ">
              <Skeleton height={25} width={70} />
              <Skeleton height={25} width={70} />
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="w-1/3 m-2 flex justify-center items-center text-white rounded-lg">
              <Skeleton height={50} width={50} />
            </div>
            <div className="w-2/3 flex flex-col ">
              <Skeleton height={25} width={70} />
              <Skeleton height={25} width={70} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderLoader;
