import React from "react";

const UserSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.89995 0.200001C3.64955 0.200001 0.199951 3.6496 0.199951 7.9C0.199951 12.1504 3.64955 15.6 7.89995 15.6C12.1504 15.6 15.6 12.1504 15.6 7.9C15.6 3.6496 12.1504 0.200001 7.89995 0.200001ZM10.6797 5.0818C11.5036 5.0818 12.1658 5.744 12.1658 6.5679C12.1658 7.3918 11.5036 8.054 10.6797 8.054C9.85575 8.054 9.19355 7.3918 9.19355 6.5679C9.18585 5.744 9.85575 5.0818 10.6797 5.0818ZM6.05965 3.8652C7.06065 3.8652 7.87685 4.6814 7.87685 5.6824C7.87685 6.6834 7.06065 7.4996 6.05965 7.4996C5.05865 7.4996 4.24245 6.6834 4.24245 5.6824C4.24245 4.6737 5.05095 3.8652 6.05965 3.8652ZM6.05965 10.8953V13.7828C4.21165 13.2053 2.74865 11.7808 2.10185 9.9636C2.91035 9.1012 4.92775 8.6623 6.05965 8.6623C6.46775 8.6623 6.98365 8.7239 7.52265 8.8317C6.25985 9.5016 6.05965 10.3871 6.05965 10.8953ZM7.89995 14.06C7.69205 14.06 7.49185 14.0523 7.29165 14.0292V10.8953C7.29165 9.8019 9.55545 9.2552 10.6797 9.2552C11.5036 9.2552 12.9281 9.5555 13.6365 10.1407C12.7356 12.4276 10.5103 14.06 7.89995 14.06Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.89995 0.200001C3.64955 0.200001 0.199951 3.6496 0.199951 7.9C0.199951 12.1504 3.64955 15.6 7.89995 15.6C12.1504 15.6 15.6 12.1504 15.6 7.9C15.6 3.6496 12.1504 0.200001 7.89995 0.200001ZM10.6797 5.0818C11.5036 5.0818 12.1658 5.744 12.1658 6.5679C12.1658 7.3918 11.5036 8.054 10.6797 8.054C9.85575 8.054 9.19355 7.3918 9.19355 6.5679C9.18585 5.744 9.85575 5.0818 10.6797 5.0818ZM6.05965 3.8652C7.06065 3.8652 7.87685 4.6814 7.87685 5.6824C7.87685 6.6834 7.06065 7.4996 6.05965 7.4996C5.05865 7.4996 4.24245 6.6834 4.24245 5.6824C4.24245 4.6737 5.05095 3.8652 6.05965 3.8652ZM6.05965 10.8953V13.7828C4.21165 13.2053 2.74865 11.7808 2.10185 9.9636C2.91035 9.1012 4.92775 8.6623 6.05965 8.6623C6.46775 8.6623 6.98365 8.7239 7.52265 8.8317C6.25985 9.5016 6.05965 10.3871 6.05965 10.8953ZM7.89995 14.06C7.69205 14.06 7.49185 14.0523 7.29165 14.0292V10.8953C7.29165 9.8019 9.55545 9.2552 10.6797 9.2552C11.5036 9.2552 12.9281 9.5555 13.6365 10.1407C12.7356 12.4276 10.5103 14.06 7.89995 14.06Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default UserSVG;
