import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner/index";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { useQuery } from "@apollo/client";
import { QUERY_KITCHEN_ORDER } from "../../query";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";

const HoldCartComponent: any = Loadable({
  loader: () => import("../../../components/Order/HoldCart/HoldCart"),
  loading: () => <TableLoader />,
});
type Props = {};
const HoldCart = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const { data: remoteOrder, loading } = useQuery(QUERY_KITCHEN_ORDER, {
    variables: {
      orderStatus: "hold",
      first: pageSize,
      offset: offset,
    },
  });
  const data = remoteOrder?.kitchenOrders?.edges ?? [];
  const pageInfo = remoteOrder?.kitchenOrders?.pageInfo ?? {};
  return (
    <div>
      <HoldCartComponent
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data}
        loading={loading}
        pageInfo={pageInfo}
      />
    </div>
  );
};

export default HoldCart;
