import React from "react";
import Loadable from "react-loadable";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { QUERY_TRANSACTIONS_VIEW } from "../../query";
import { useQuery, useReactiveVar } from "@apollo/client";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";
import { selectedDatePickerItemVar } from "../../../components/LocalState/Cache";
import moment from "moment";

const TransactionComponent: any = Loadable({
  loader: () => import("../../../components/Order/Transaction/Transaction"),
  loading: () => <TableLoader />,
});
type Props = {};
const Transaction = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const { startDate, endDate }: any = useReactiveVar(selectedDatePickerItemVar);
  const { data: transactions, loading } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: {
      first: pageSize,
      offset: offset,
      created_Gte: startDate
        ? moment(startDate).startOf("day").format("yyyy-MM-DDThh:mm:ss")
        : null,
      created_Lte: endDate
        ? moment(endDate).endOf("day").format("yyyy-MM-DDThh:mm:ss")
        : null,
      account: "",
      account_IsVisible: true,
    },
  });

  const data = transactions?.transactions?.edges ?? [];
  const pageInfo = transactions?.transactions?.pageInfo ?? {};
  return (
    <div>
      <TransactionComponent
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data}
        loading={loading}
        pageInfo={pageInfo}
      />
    </div>
  );
};

export default Transaction;
