import React from "react";

const AttendenceSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="16"
          height="12"
          viewBox="0 0 15 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.18 0.199997H12.98C13.629 0.199997 14.16 0.730997 14.16 1.38V10.82C14.16 11.469 13.629 12 12.98 12H1.18C0.531 12 0 11.469 0 10.82V1.38C0 0.730997 0.531 0.199997 1.18 0.199997ZM12.685 10.82C12.8502 10.82 12.98 10.6902 12.98 10.525V1.675C12.98 1.5098 12.8502 1.38 12.685 1.38H1.475C1.3098 1.38 1.18 1.5098 1.18 1.675V10.525C1.18 10.6902 1.3098 10.82 1.475 10.82H12.685ZM2.94732 4.58894C2.94732 5.23794 3.481 5.77428 4.13 5.77428C4.779 5.77428 5.318 5.23794 5.318 4.58894C5.318 3.93994 4.779 3.4036 4.13 3.4036C3.481 3.4036 2.94732 3.93994 2.94732 4.58894ZM5.9 9.04998C5.9 9.37448 5.6345 9.63998 5.31 9.63998L2.95 9.63586C2.62432 9.63586 2.36 9.37566 2.36 9.04998V7.86998C2.36 6.89648 3.1565 6.09998 4.13 6.09998C5.1035 6.09998 5.9 6.89648 5.9 7.86998V9.04998ZM7.67 9.63998H11.21C11.5363 9.63998 11.8 9.37626 11.8 9.04998C11.8 8.72372 11.5363 8.45998 11.21 8.45998H7.67C7.34374 8.45998 7.08 8.72372 7.08 9.04998C7.08 9.37626 7.34374 9.63998 7.67 9.63998ZM7.67 7.27998H11.21C11.5363 7.27998 11.8 7.01566 11.8 6.68998C11.8 6.3643 11.5363 6.09998 11.21 6.09998H7.67C7.34374 6.09998 7.08 6.3643 7.08 6.68998C7.08 7.01566 7.34374 7.27998 7.67 7.27998ZM7.67 4.91998H11.21C11.5363 4.91998 11.8 4.65566 11.8 4.32998C11.8 4.0043 11.5363 3.73998 11.21 3.73998H7.67C7.34374 3.73998 7.08 4.0043 7.08 4.32998C7.08 4.65566 7.34374 4.91998 7.67 4.91998Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="12"
          viewBox="0 0 15 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.18 0.199997H12.98C13.629 0.199997 14.16 0.730997 14.16 1.38V10.82C14.16 11.469 13.629 12 12.98 12H1.18C0.531 12 0 11.469 0 10.82V1.38C0 0.730997 0.531 0.199997 1.18 0.199997ZM12.685 10.82C12.8502 10.82 12.98 10.6902 12.98 10.525V1.675C12.98 1.5098 12.8502 1.38 12.685 1.38H1.475C1.3098 1.38 1.18 1.5098 1.18 1.675V10.525C1.18 10.6902 1.3098 10.82 1.475 10.82H12.685ZM2.94732 4.58894C2.94732 5.23794 3.481 5.77428 4.13 5.77428C4.779 5.77428 5.318 5.23794 5.318 4.58894C5.318 3.93994 4.779 3.4036 4.13 3.4036C3.481 3.4036 2.94732 3.93994 2.94732 4.58894ZM5.9 9.04998C5.9 9.37448 5.6345 9.63998 5.31 9.63998L2.95 9.63586C2.62432 9.63586 2.36 9.37566 2.36 9.04998V7.86998C2.36 6.89648 3.1565 6.09998 4.13 6.09998C5.1035 6.09998 5.9 6.89648 5.9 7.86998V9.04998ZM7.67 9.63998H11.21C11.5363 9.63998 11.8 9.37626 11.8 9.04998C11.8 8.72372 11.5363 8.45998 11.21 8.45998H7.67C7.34374 8.45998 7.08 8.72372 7.08 9.04998C7.08 9.37626 7.34374 9.63998 7.67 9.63998ZM7.67 7.27998H11.21C11.5363 7.27998 11.8 7.01566 11.8 6.68998C11.8 6.3643 11.5363 6.09998 11.21 6.09998H7.67C7.34374 6.09998 7.08 6.3643 7.08 6.68998C7.08 7.01566 7.34374 7.27998 7.67 7.27998ZM7.67 4.91998H11.21C11.5363 4.91998 11.8 4.65566 11.8 4.32998C11.8 4.0043 11.5363 3.73998 11.21 3.73998H7.67C7.34374 3.73998 7.08 4.0043 7.08 4.32998C7.08 4.65566 7.34374 4.91998 7.67 4.91998Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default AttendenceSVG;
