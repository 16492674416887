import { gql } from "@apollo/client";
const GET_SELECTED_DATE_PICKER = gql`
  query GetSelectedDatePickerItem {
    datePickerItems @client
  }
`;
const GET_SEARCH_VALUE = gql`
  query GetSearchValueItem {
    searchItems @client
  }
`;
export { GET_SELECTED_DATE_PICKER, GET_SEARCH_VALUE };
