import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner";
import { useGetHook } from "../../customhooks/useApiData";

const ProfileComponent: any = Loadable({
  loader: () => import("../../components/Profile/Profile"),
  loading: DataLoadSpinner,
});

type Props = {};

const Profile = (props: Props) => {
  const { data, isLoading: userLoading } = useGetHook({
    url: "/user/me",
    queryKey: [`user/me`],
    params: {},
  });
  return (
    <>
      <ProfileComponent data={data} userLoading={userLoading} />
    </>
  );
};

export default Profile;
