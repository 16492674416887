import React, { useCallback, useEffect } from "react";
import CloseIcon from "../../../assets/icon/closeicon.svg";
import IconButton from "../Button/IconButton/IconButton";
import { GET_SEARCH_VALUE } from "../../LocalState/LocalState";
import { searchCache, searchItemsVar } from "../../LocalState/Cache";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { debounce } from "lodash";
type Props = { setIsShowSearch: any };

const SearchBox = (props: Props) => {
  const { setOffset } = usePaginationState();
  const { setIsShowSearch } = props;
  const [input, setInput] = React.useState("");

  const debouncedSearch = useCallback(
    debounce((searchTerm: any) => {
      const searchList = {
        searchInput: searchTerm,
      };
      searchCache.writeQuery({
        query: GET_SEARCH_VALUE,
        data: {
          searchItems: searchItemsVar(searchList),
        },
      });
    }, 400),
    []
  );

  const onChangeInput = (event: any) => {
    setInput(event.target.value);
    setOffset(0);
    debouncedSearch(event.target.value);
  };
  useEffect(() => {}, [window.location.href]);

  const handleCloseSearchBar = () => {
    setIsShowSearch((pre: any) => !pre);
    const searchList = {
      searchInput: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_VALUE,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
  };
  return (
    <div className="relative">
      <input
        type="text"
        className="pl-2 pr-6 h-9 w-[250px] outline-none border-none rounded-sm "
        placeholder="Search"
        value={input}
        onChange={(e) => onChangeInput(e)}
      />
      <img
        src={CloseIcon}
        alt=""
        className="absolute top-2 right-1 w-5"
        onClick={handleCloseSearchBar}
      />
    </div>
  );
};

export default SearchBox;
