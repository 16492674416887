import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner/index";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { useGetHook } from "../../../customhooks/useApiData";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";
import { useReactiveVar } from "@apollo/client";
import { searchItemsVar } from "../../../components/LocalState/Cache";

const InActiveUserComponent: any = Loadable({
  loader: () =>
    import("../../../components/UserManagement/InActiveUser/InActiveUser"),
  loading: () => <TableLoader />,
});
type Props = {};

const InActiveUser = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const searchItems: any = useReactiveVar(searchItemsVar);

  const { data, isLoading: userLoading } = useGetHook({
    url: "/user",
    queryKey: [`user/${pageSize}${offset}${searchItems?.searchInput}`],
    params: {
      limit: pageSize,
      offset: offset,
      is_active: false,
      is_staff: true,
      search: searchItems?.searchInput,
    },
  });
  return (
    <div>
      <InActiveUserComponent
        data={data}
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
        userLoading={userLoading}
      />
    </div>
  );
};

export default InActiveUser;
