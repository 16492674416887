import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner";
import { useParams } from "react-router-dom";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { QUERY_KITCHEN_ORDER_ITEMS, QUERY_ORDERED_ITEMS } from "../../query";
import { useQuery } from "@apollo/client";
import DetailPageLoader from "../../../components/Reusable/Loader/DetailPageLoader";
type Props = {};

const HoldOrderDetailsComponent: any = Loadable({
  loader: () => import("../../../components/Order/HoldCart/HoldOrderDetails"),
  loading: () => <DetailPageLoader count={15} />,
});

const HoldOrderDetails = (props: Props) => {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY_KITCHEN_ORDER_ITEMS, {
    variables: {
      id: id,
    },
  });
  return (
    <>
      <HoldOrderDetailsComponent data={data?.kitchenOrder} loading={loading} />
    </>
  );
};

export default HoldOrderDetails;
