import React from "react";

const QuickSalesSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_818_553"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="14"
            height="14"
          >
            <path
              d="M13.3 0.799988H0.800049V13.3H13.3V0.799988Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_818_553)">
            <path
              d="M13.0248 11.5877H11.3598V4.57657L12.0104 3.93271L12.0104 4.93361C12.0104 5.08563 12.1336 5.20889 12.2857 5.20889C12.4377 5.20889 12.5609 5.08563 12.5609 4.93361L12.5609 3.23969C12.5609 3.08765 12.4377 2.96441 12.2856 2.96441H10.5918C10.4398 2.96441 10.3165 3.08765 10.3165 3.23969C10.3165 3.39171 10.4398 3.51497 10.5918 3.51497H11.6498L7.54451 7.57811L5.59775 5.61733C5.54607 5.56525 5.47575 5.53597 5.40241 5.53597C5.40237 5.53597 5.40243 5.53597 5.40241 5.53597C5.32903 5.53599 5.25863 5.56531 5.20695 5.61743L1.49583 9.35935C1.38879 9.46729 1.38949 9.64161 1.49743 9.74865C1.55113 9.80191 1.62119 9.82849 1.69127 9.82849C1.76209 9.82849 1.83291 9.80133 1.88673 9.74705L2.21673 9.41431V11.5878H1.35061V2.23701C1.35061 2.08497 1.22737 1.96173 1.07533 1.96173C0.923289 1.96173 0.800049 2.08497 0.800049 2.23701V11.863C0.800049 12.015 0.923289 12.1383 1.07533 12.1383H13.0248C13.1768 12.1383 13.3 12.015 13.3 11.863C13.3 11.711 13.1768 11.5877 13.0248 11.5877ZM10.8092 11.5877H7.81811V8.08193L10.8092 5.12151V11.5877ZM5.67765 6.47921L7.26753 8.08051V11.5877H5.67765V6.47921ZM2.76729 8.85913L5.12709 6.47971V11.5877H2.76729V8.85913Z"
              fill="white"
            />
            <path
              d="M5.40241 5.53597C5.47575 5.53597 5.54607 5.56525 5.59775 5.61733L7.54451 7.57811L11.6498 3.51497H10.5918C10.4398 3.51497 10.3165 3.39171 10.3165 3.23969C10.3165 3.08765 10.4398 2.96441 10.5918 2.96441H12.2856C12.4377 2.96441 12.5609 3.08765 12.5609 3.23969L12.5609 4.93361C12.5609 5.08563 12.4377 5.20889 12.2857 5.20889C12.1336 5.20889 12.0104 5.08563 12.0104 4.93361L12.0104 3.93271L11.3598 4.57657V11.5877H13.0248C13.1768 11.5877 13.3 11.711 13.3 11.863C13.3 12.015 13.1768 12.1383 13.0248 12.1383H1.07533C0.923289 12.1383 0.800049 12.015 0.800049 11.863V2.23701C0.800049 2.08497 0.923289 1.96173 1.07533 1.96173C1.22737 1.96173 1.35061 2.08497 1.35061 2.23701V11.5878H2.21673V9.41431L1.88673 9.74705C1.83291 9.80133 1.76209 9.82849 1.69127 9.82849C1.62119 9.82849 1.55113 9.80191 1.49743 9.74865C1.38949 9.64161 1.38879 9.46729 1.49583 9.35935L5.20695 5.61743C5.25863 5.56531 5.32903 5.53599 5.40241 5.53597ZM5.40241 5.53597C5.40237 5.53597 5.40243 5.53597 5.40241 5.53597ZM10.8092 11.5877H7.81811V8.08193L10.8092 5.12151V11.5877ZM5.67765 6.47921L7.26753 8.08051V11.5877H5.67765V6.47921ZM2.76729 8.85913L5.12709 6.47971V11.5877H2.76729V8.85913Z"
              stroke="white"
            />
          </g>
        </svg>
      ) : (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_818_553"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="14"
            height="14"
          >
            <path
              d="M13.3 0.799988H0.800049V13.3H13.3V0.799988Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_818_553)">
            <path
              d="M13.0248 11.5877H11.3598V4.57657L12.0104 3.93271L12.0104 4.93361C12.0104 5.08563 12.1336 5.20889 12.2857 5.20889C12.4377 5.20889 12.5609 5.08563 12.5609 4.93361L12.5609 3.23969C12.5609 3.08765 12.4377 2.96441 12.2856 2.96441H10.5918C10.4398 2.96441 10.3165 3.08765 10.3165 3.23969C10.3165 3.39171 10.4398 3.51497 10.5918 3.51497H11.6498L7.54451 7.57811L5.59775 5.61733C5.54607 5.56525 5.47575 5.53597 5.40241 5.53597C5.40237 5.53597 5.40243 5.53597 5.40241 5.53597C5.32903 5.53599 5.25863 5.56531 5.20695 5.61743L1.49583 9.35935C1.38879 9.46729 1.38949 9.64161 1.49743 9.74865C1.55113 9.80191 1.62119 9.82849 1.69127 9.82849C1.76209 9.82849 1.83291 9.80133 1.88673 9.74705L2.21673 9.41431V11.5878H1.35061V2.23701C1.35061 2.08497 1.22737 1.96173 1.07533 1.96173C0.923289 1.96173 0.800049 2.08497 0.800049 2.23701V11.863C0.800049 12.015 0.923289 12.1383 1.07533 12.1383H13.0248C13.1768 12.1383 13.3 12.015 13.3 11.863C13.3 11.711 13.1768 11.5877 13.0248 11.5877ZM10.8092 11.5877H7.81811V8.08193L10.8092 5.12151V11.5877ZM5.67765 6.47921L7.26753 8.08051V11.5877H5.67765V6.47921ZM2.76729 8.85913L5.12709 6.47971V11.5877H2.76729V8.85913Z"
              fill="#14191E"
            />
            <path
              d="M5.40241 5.53597C5.47575 5.53597 5.54607 5.56525 5.59775 5.61733L7.54451 7.57811L11.6498 3.51497H10.5918C10.4398 3.51497 10.3165 3.39171 10.3165 3.23969C10.3165 3.08765 10.4398 2.96441 10.5918 2.96441H12.2856C12.4377 2.96441 12.5609 3.08765 12.5609 3.23969L12.5609 4.93361C12.5609 5.08563 12.4377 5.20889 12.2857 5.20889C12.1336 5.20889 12.0104 5.08563 12.0104 4.93361L12.0104 3.93271L11.3598 4.57657V11.5877H13.0248C13.1768 11.5877 13.3 11.711 13.3 11.863C13.3 12.015 13.1768 12.1383 13.0248 12.1383H1.07533C0.923289 12.1383 0.800049 12.015 0.800049 11.863V2.23701C0.800049 2.08497 0.923289 1.96173 1.07533 1.96173C1.22737 1.96173 1.35061 2.08497 1.35061 2.23701V11.5878H2.21673V9.41431L1.88673 9.74705C1.83291 9.80133 1.76209 9.82849 1.69127 9.82849C1.62119 9.82849 1.55113 9.80191 1.49743 9.74865C1.38949 9.64161 1.38879 9.46729 1.49583 9.35935L5.20695 5.61743C5.25863 5.56531 5.32903 5.53599 5.40241 5.53597ZM5.40241 5.53597C5.40237 5.53597 5.40243 5.53597 5.40241 5.53597ZM10.8092 11.5877H7.81811V8.08193L10.8092 5.12151V11.5877ZM5.67765 6.47921L7.26753 8.08051V11.5877H5.67765V6.47921ZM2.76729 8.85913L5.12709 6.47971V11.5877H2.76729V8.85913Z"
              stroke="#14191E"
            />
          </g>
        </svg>
      )}
    </>
  );
};

export default QuickSalesSVG;
