import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner";
import { useSyncInfoGetHook } from "../../customhooks/useApiData";

const SyncInfoComponent: any = Loadable({
  loader: () => import("../../components/Setting/SyncInfo"),
  loading: DataLoadSpinner,
});

type Props = {};

const SyncInfo = (props: Props) => {
  const url = window.location.origin;
  const origin = url.split("//")[1];
  const { data, isLoading: syncInfoLoading } = useSyncInfoGetHook({
    url: `/${origin}`,
    queryKey: [`syncInfo/`],
    params: {},
  });

  return (
    <>
      <SyncInfoComponent
        syncInfoData={data}
        syncInfoLoading={syncInfoLoading}
      />
    </>
  );
};

export default SyncInfo;
