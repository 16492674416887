import React from "react";

const DetailsSalesSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4993 16.666C17.3899 16.666 17.2815 16.6445 17.1804 16.6026C17.0793 16.5608 16.9874 16.4994 16.91 16.422C16.8326 16.3446 16.7713 16.2527 16.7294 16.1516C16.6875 16.0505 16.666 15.9421 16.666 15.8327V4.16602C16.6658 3.94507 16.5779 3.73324 16.4217 3.577C16.2655 3.42077 16.0536 3.3329 15.8327 3.33268H4.16602C3.94507 3.3329 3.73324 3.42077 3.577 3.577C3.42077 3.73324 3.3329 3.94507 3.33268 4.16602V15.8327C3.33268 16.0537 3.24488 16.2657 3.0886 16.4219C2.93232 16.5782 2.72036 16.666 2.49935 16.666C2.27834 16.666 2.06637 16.5782 1.91009 16.4219C1.75381 16.2657 1.66602 16.0537 1.66602 15.8327V4.16602C1.66674 3.5032 1.93036 2.86773 2.39905 2.39905C2.86773 1.93036 3.5032 1.66674 4.16602 1.66602H15.8327C16.4955 1.66674 17.131 1.93036 17.5996 2.39905C18.0683 2.86773 18.332 3.5032 18.3327 4.16602V15.8327C18.3327 15.9421 18.3112 16.0505 18.2693 16.1516C18.2274 16.2527 18.1661 16.3446 18.0887 16.422C18.0113 16.4994 17.9194 16.5608 17.8183 16.6026C17.7172 16.6445 17.6088 16.666 17.4993 16.666Z"
            fill="white"
          />
          <path
            d="M12.5 18.334H7.5C6.83718 18.3333 6.20172 18.0696 5.73303 17.601C5.26435 17.1323 5.00072 16.4968 5 15.834V11.6673C5 11.4463 5.0878 11.2343 5.24408 11.0781C5.40036 10.9218 5.61232 10.834 5.83333 10.834C6.05435 10.834 6.26631 10.9218 6.42259 11.0781C6.57887 11.2343 6.66667 11.4463 6.66667 11.6673V15.834C6.66689 16.0549 6.75476 16.2668 6.91099 16.423C7.06722 16.5792 7.27905 16.6671 7.5 16.6673H12.5C12.7209 16.6671 12.9328 16.5792 13.089 16.423C13.2452 16.2668 13.3331 16.0549 13.3333 15.834V11.6673C13.3333 11.4463 13.4211 11.2343 13.5774 11.0781C13.7337 10.9218 13.9457 10.834 14.1667 10.834C14.3877 10.834 14.5996 10.9218 14.7559 11.0781C14.9122 11.2343 15 11.4463 15 11.6673V15.834C14.9993 16.4968 14.7357 17.1323 14.267 17.601C13.7983 18.0696 13.1628 18.3333 12.5 18.334Z"
            fill="white"
          />
          <path
            d="M13.3333 9.16602H6.66667C6.22479 9.16551 5.80116 8.98975 5.48871 8.6773C5.17626 8.36485 5.00051 7.94122 5 7.49935V1.66602H15V7.49935C14.9995 7.94122 14.8237 8.36485 14.5113 8.6773C14.1988 8.98975 13.7752 9.16551 13.3333 9.16602ZM6.66667 3.33268V7.49935H13.3345L13.3333 3.33268H6.66667Z"
            fill="white"
          />
          <path
            d="M10 14.166C9.36508 14.1917 8.74577 13.9652 8.27736 13.5358C7.80894 13.1064 7.52947 12.5091 7.5 11.8743C7.5 11.6533 7.5878 11.4414 7.74408 11.2851C7.90036 11.1288 8.11232 11.041 8.33333 11.041C8.55435 11.041 8.76631 11.1288 8.92259 11.2851C9.07887 11.4414 9.16667 11.6533 9.16667 11.8743C9.16667 12.2129 9.54833 12.4993 10 12.4993C10.4517 12.4993 10.8333 12.2129 10.8333 11.8743C10.8333 11.6533 10.9211 11.4414 11.0774 11.2851C11.2337 11.1288 11.4457 11.041 11.6667 11.041C11.8877 11.041 12.0996 11.1288 12.2559 11.2851C12.4122 11.4414 12.5 11.6533 12.5 11.8743C12.4705 12.5091 12.1911 13.1064 11.7226 13.5358C11.2542 13.9652 10.6349 14.1917 10 14.166Z"
            fill="white"
          />
          <path
            d="M4.99935 6.66667C5.45959 6.66667 5.83268 6.29357 5.83268 5.83333C5.83268 5.3731 5.45959 5 4.99935 5C4.53911 5 4.16602 5.3731 4.16602 5.83333C4.16602 6.29357 4.53911 6.66667 4.99935 6.66667Z"
            fill="white"
          />
          <path
            d="M14.9993 6.66667C15.4596 6.66667 15.8327 6.29357 15.8327 5.83333C15.8327 5.3731 15.4596 5 14.9993 5C14.5391 5 14.166 5.3731 14.166 5.83333C14.166 6.29357 14.5391 6.66667 14.9993 6.66667Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4993 16.666C17.3899 16.666 17.2815 16.6445 17.1804 16.6026C17.0793 16.5608 16.9874 16.4994 16.91 16.422C16.8326 16.3446 16.7713 16.2527 16.7294 16.1516C16.6875 16.0505 16.666 15.9421 16.666 15.8327V4.16602C16.6658 3.94507 16.5779 3.73324 16.4217 3.577C16.2655 3.42077 16.0536 3.3329 15.8327 3.33268H4.16602C3.94507 3.3329 3.73324 3.42077 3.577 3.577C3.42077 3.73324 3.3329 3.94507 3.33268 4.16602V15.8327C3.33268 16.0537 3.24488 16.2657 3.0886 16.4219C2.93232 16.5782 2.72036 16.666 2.49935 16.666C2.27834 16.666 2.06637 16.5782 1.91009 16.4219C1.75381 16.2657 1.66602 16.0537 1.66602 15.8327V4.16602C1.66674 3.5032 1.93036 2.86773 2.39905 2.39905C2.86773 1.93036 3.5032 1.66674 4.16602 1.66602H15.8327C16.4955 1.66674 17.131 1.93036 17.5996 2.39905C18.0683 2.86773 18.332 3.5032 18.3327 4.16602V15.8327C18.3327 15.9421 18.3112 16.0505 18.2693 16.1516C18.2274 16.2527 18.1661 16.3446 18.0887 16.422C18.0113 16.4994 17.9194 16.5608 17.8183 16.6026C17.7172 16.6445 17.6088 16.666 17.4993 16.666Z"
            fill="#14191E"
          />
          <path
            d="M12.5 18.334H7.5C6.83718 18.3333 6.20172 18.0696 5.73303 17.601C5.26435 17.1323 5.00072 16.4968 5 15.834V11.6673C5 11.4463 5.0878 11.2343 5.24408 11.0781C5.40036 10.9218 5.61232 10.834 5.83333 10.834C6.05435 10.834 6.26631 10.9218 6.42259 11.0781C6.57887 11.2343 6.66667 11.4463 6.66667 11.6673V15.834C6.66689 16.0549 6.75476 16.2668 6.91099 16.423C7.06722 16.5792 7.27905 16.6671 7.5 16.6673H12.5C12.7209 16.6671 12.9328 16.5792 13.089 16.423C13.2452 16.2668 13.3331 16.0549 13.3333 15.834V11.6673C13.3333 11.4463 13.4211 11.2343 13.5774 11.0781C13.7337 10.9218 13.9457 10.834 14.1667 10.834C14.3877 10.834 14.5996 10.9218 14.7559 11.0781C14.9122 11.2343 15 11.4463 15 11.6673V15.834C14.9993 16.4968 14.7357 17.1323 14.267 17.601C13.7983 18.0696 13.1628 18.3333 12.5 18.334Z"
            fill="#14191E"
          />
          <path
            d="M13.3333 9.16602H6.66667C6.22479 9.16551 5.80116 8.98975 5.48871 8.6773C5.17626 8.36485 5.00051 7.94122 5 7.49935V1.66602H15V7.49935C14.9995 7.94122 14.8237 8.36485 14.5113 8.6773C14.1988 8.98975 13.7752 9.16551 13.3333 9.16602ZM6.66667 3.33268V7.49935H13.3345L13.3333 3.33268H6.66667Z"
            fill="#14191E"
          />
          <path
            d="M10 14.166C9.36508 14.1917 8.74577 13.9652 8.27736 13.5358C7.80894 13.1064 7.52947 12.5091 7.5 11.8743C7.5 11.6533 7.5878 11.4414 7.74408 11.2851C7.90036 11.1288 8.11232 11.041 8.33333 11.041C8.55435 11.041 8.76631 11.1288 8.92259 11.2851C9.07887 11.4414 9.16667 11.6533 9.16667 11.8743C9.16667 12.2129 9.54833 12.4993 10 12.4993C10.4517 12.4993 10.8333 12.2129 10.8333 11.8743C10.8333 11.6533 10.9211 11.4414 11.0774 11.2851C11.2337 11.1288 11.4457 11.041 11.6667 11.041C11.8877 11.041 12.0996 11.1288 12.2559 11.2851C12.4122 11.4414 12.5 11.6533 12.5 11.8743C12.4705 12.5091 12.1911 13.1064 11.7226 13.5358C11.2542 13.9652 10.6349 14.1917 10 14.166Z"
            fill="#14191E"
          />
          <path
            d="M4.99935 6.66667C5.45959 6.66667 5.83268 6.29357 5.83268 5.83333C5.83268 5.3731 5.45959 5 4.99935 5C4.53911 5 4.16602 5.3731 4.16602 5.83333C4.16602 6.29357 4.53911 6.66667 4.99935 6.66667Z"
            fill="#14191E"
          />
          <path
            d="M14.9993 6.66667C15.4596 6.66667 15.8327 6.29357 15.8327 5.83333C15.8327 5.3731 15.4596 5 14.9993 5C14.5391 5 14.166 5.3731 14.166 5.83333C14.166 6.29357 14.5391 6.66667 14.9993 6.66667Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default DetailsSalesSVG;
