import React from "react";
import Skeleton from "react-loading-skeleton";

type Props = {
  count?: number;
};

const DetailPageLoader = (props: Props) => {
  const { count } = props;
  return (
    <>
      <div className="p-3">
        <div className="bg-white p-2">
          <Skeleton height={35} count={count ?? 10} />
        </div>
        <Skeleton height={35} className="my-5" />
        <Skeleton height={35} />
      </div>
      <div className="flex justify-between w-full fixed bottom-0 bg-white p-2">
        <Skeleton height={30} width={100} />
        <Skeleton height={30} width={100} />
      </div>
    </>
  );
};

export default DetailPageLoader;
