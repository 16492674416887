import React, { Fragment, useEffect, useState } from "react";
import HamBurgerIcon from "../../assets/icon/heroicons-solid_menu-alt-2 (1).svg";
import HelpIcon from "../../assets/icon/help1 1.svg";
import UserIcon from "../../assets/icon/user.svg";
import AboutIcon from "../../assets/icon/abouticon.svg";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import Sidebar from "../SideBar";
import Datepicker from "react-tailwindcss-datepicker";
import SearchBox from "../Reusable/SearchBox";
import calendar from "../../assets/icon/calendar.svg";
import SearchIcon from "../../assets/icon/search.svg";
import {
  searchCache,
  searchItemsVar,
  selectedDatePickerCache,
  selectedDatePickerItemVar,
} from "../LocalState/Cache";
import {
  GET_SEARCH_VALUE,
  GET_SELECTED_DATE_PICKER,
} from "../LocalState/LocalState";
import { useReactiveVar } from "@apollo/client";
import { classNames } from "../../utils/classNames";

type Props = {
  isOpenSidebar: any;
  setIsOpenSidebar: any;
};

const Navbar = (props: Props) => {
  const { isOpenSidebar, setIsOpenSidebar } = props;
  const [location, setLocation] = useState([]);
  const [lastArray, setLastArray] = useState("");
  const [datepickerKey, setDatepickerKey] = useState(Date.now());

  useEffect(() => {
    const handleLocationChange = () => {
      const url = window.location.pathname.replace(/-/g, " ").split("/");
      setLocation(url);
      setLastArray(url.pop());
    };
    window.addEventListener("popstate", handleLocationChange);
    handleLocationChange();
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const locationPath = window.location.pathname;

  const rangeDatePickerLocation = [
    "/transactions",
    "/productwise-report",
    "/categorywise-report",
    "/userwise-report",
    "/floorwise-report",
    "/tablewise-report",
    "/refund",
    "/cashout",
    "/void-order-expenses",
    "/discount-expenses",
    "/reward-programs",
  ];

  const checkLocation = rangeDatePickerLocation.includes(locationPath);

  const searchBoxUrl = [
    "/active-users",
    "/inactive-users",
    "/customer",
    "/orders",
    "/productwise-report",
  ];
  const searchLocation = searchBoxUrl.includes(locationPath);

  const value: any = {
    startDate: null,
    endDate: null,
  };

  const handleChange = (e: any) => {
    selectedDatePickerCache.writeQuery({
      query: GET_SELECTED_DATE_PICKER,
      data: {
        datePickerItems: selectedDatePickerItemVar(e),
      },
    });
  };
  const dateValue: any = useReactiveVar(selectedDatePickerItemVar);
  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    selectedDatePickerCache.writeQuery({
      query: GET_SELECTED_DATE_PICKER,
      data: {
        datePickerItems: selectedDatePickerItemVar(value),
      },
    });

    searchCache.writeQuery({
      query: GET_SEARCH_VALUE,
      data: {
        searchItems: searchItemsVar({
          searchInput: "",
        }),
      },
    });
    setIsShowSearch(false);
    setDatepickerKey(Date.now());
  }, [window.location.pathname]);

  return (
    <>
      <div className="bg-blue-800 py-1 px-3 w-full flex justify-between sticky top-0 z-20">
        {/* siderbar button */}
        <div className="my-auto flex gap-x-4">
          <button
            onClick={() => setIsOpenSidebar(!isOpenSidebar)}
            className="my-auto"
          >
            <img src={HamBurgerIcon} alt="hamburger" />
          </button>
          {!isShowSearch && (
            <>
              {checkLocation && (
                <div>
                  <img
                    src={calendar}
                    alt="calendar"
                    className="w-6 h-6 absolute cursor-pointer"
                  />
                  <Datepicker
                    key={datepickerKey}
                    useRange={false}
                    value={dateValue ? dateValue : value}
                    onChange={(e: any) => handleChange(e)}
                    toggleClassName="hidden"
                    inputClassName="w-6 relative opacity-5 cursor-pointer "
                    maxDate={new Date()}
                    readOnly={true}
                  />
                </div>
              )}
              {window.location.pathname === "/attendance" && (
                <div>
                  <img
                    src={calendar}
                    alt="calendar"
                    className="w-6 h-6 absolute cursor-pointer"
                  />
                  <Datepicker
                    key={datepickerKey}
                    useRange={false}
                    asSingle={true}
                    value={dateValue ? dateValue : value}
                    onChange={(e: any) => handleChange(e)}
                    toggleClassName="hidden"
                    inputClassName="w-6 relative opacity-5 cursor-pointer "
                    maxDate={new Date()}
                    readOnly={true}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {/* navbar title */}
        {!isShowSearch && (
          <div
            className={classNames(
              searchLocation && "pl-8",
              "capitalize font-medium text-white text-lg my-auto "
            )}
          >
            {location && location[1] && location[1] !== lastArray ? (
              <p>{location[1]} Details</p>
            ) : (
              <>
                {lastArray === "" ? (
                  <>
                    <p>Dashboard</p>
                  </>
                ) : (
                  <p>{lastArray}</p>
                )}
              </>
            )}
          </div>
        )}
        {/* Search box */}
        {searchLocation && (
          <>
            {isShowSearch && (
              <div className="my-auto pl-5">
                <SearchBox setIsShowSearch={setIsShowSearch} />
              </div>
            )}
          </>
        )}
        {/*helper box */}
        <div className="flex gap-2">
          <div className="flex items-center justify-center gap-x-5">
            {searchLocation && (
              <>
                {!isShowSearch && (
                  <img
                    src={SearchIcon}
                    alt="SearchIcon"
                    className="w-5 h-5"
                    onClick={() => setIsShowSearch((pre: boolean) => !pre)}
                  />
                )}
              </>
            )}
          </div>

          {/* Profile dropdown */}
          <Menu as="div" className="ml-0 z-50 my-auto">
            {({ open }) => (
              <>
                <Menu.Button>
                  <img
                    className="h-8 w-8 p-1 text-center hover:bg-indigo-800  mt-2 "
                    src={HelpIcon}
                    alt=""
                    title="Account"
                  />
                </Menu.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-5 rounded py-1 pe-4 shadow-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <Menu.Item>
                      <a
                        href="https://www.youtube.com/@dibtechbusinesssolution1445"
                        className={"flex px-4 py-2 text-sm text-gray-700 gap-2"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={UserIcon} alt="UserIcon" />
                        Documentation
                      </a>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <Sidebar
        setIsOpenSidebar={setIsOpenSidebar}
        isOpenSidebar={isOpenSidebar}
      />
    </>
  );
};

export default Navbar;
