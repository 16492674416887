import { InMemoryCache, makeVar } from "@apollo/client";

export const selectedDatePickerItemVar: any = makeVar({});
export const selectedDatePickerCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        datePickerItems: {
          read() {
            return selectedDatePickerItemVar();
          },
        },
      },
    },
  },
});

export const searchItemsVar: any = makeVar({});
export const searchCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchItems: {
          read() {
            return searchItemsVar();
          },
        },
      },
    },
  },
});
