import React from "react";

type Props = {
  icon: any;
  title?: string;
  onClick?: any;
};

const IconButton = (props: Props) => {
  const { icon, title, onClick } = props;
  return (
    <button className="my-1 flex" onClick={onClick}>
      <img src={icon} alt="" />
      <p className="text-sm">{title}</p>
    </button>
  );
};

export default IconButton;
