import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  count?: number;
  showBar?: boolean;
};

const TableLoader = (props: Props) => {
  const { count, showBar = true } = props;

  return (
    <>
      <div className="p-3">
        <Skeleton height={30} />
        <Skeleton height={40} count={count ?? 10} />
      </div>
      {showBar && (
        <div className="flex justify-between w-full fixed bottom-0 bg-white p-2 ">
          <Skeleton height={30} width={100} />
          <Skeleton height={30} width={100} />
        </div>
      )}
    </>
  );
};

export default TableLoader;
