import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";

const DashboardLayout = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);

  return (
    <div className="w-full bg-[#F8F9FA] min-h-screen ">
      <Navbar
        isOpenSidebar={isOpenSidebar}
        setIsOpenSidebar={setIsOpenSidebar}
      />
      <div onClick={() => setIsOpenSidebar(false)}>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
