import { Outlet, Route, Routes, createBrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layout/DashboardLayout";
import About from "../pages/About/About";
import DashBoard from "../pages/DashBoard/DashBoard";
import Profile from "../pages/Profile/Profile";
import Attendance from "../pages/Attendance/Attendance";
import ActiveUser from "../pages/UserManagement/ActiveUser/ActiveUser";
import InActiveUser from "../pages/UserManagement/InActiveUser/InActiveUser";
import OrderDetails from "../pages/Order/Orders/OrderDetails";
import Orders from "../pages/Order/Orders/Orders";
import HoldCart from "../pages/Order/HoldCart/HoldCart";
import Customer from "../pages/Order/Customer/Customer";
import RemoteOrder from "../pages/Order/RemoteOrder/RemoteOrder";
import Transactions from "../pages/Order/Transaction/Transactions";
import SalesReport from "../pages/Reports/SalesReport";
import UserWiseReport from "../pages/Reports/UserWiseReport";
import ProductWiseReport from "../pages/Reports/ProductWiseReport";
import TableWiseReport from "../pages/Reports/TableWiseReport";
import FloorWiseReport from "../pages/Reports/FloorWiseReport";
import CaregoryWiseReport from "../pages/Reports/CategoryWiseReport";
import RefundExpenses from "../pages/ExpensesReport/Refund";
import CashoutExpenses from "../pages/ExpensesReport/Cashout";
import DiscountExpenses from "../pages/ExpensesReport/Discount";
import VoidExpenses from "../pages/ExpensesReport/Void";
import RewardProgramExpenses from "../pages/ExpensesReport/RewardProgram";
import DetailsRefundExpenses from "../pages/ExpensesReport/Refund/DetailsRefund";
import DetailsDiscount from "../pages/ExpensesReport/Discount/DetailsDiscount";
import DetailSalesReport from "../pages/Reports/DetailsSalesReport";
import HourlySalesReport from "../pages/Reports/HourlySalesReport";
import QuickSalesReport from "../pages/Reports/QuickSalesReport";
import DetailsRewardProgram from "../pages/ExpensesReport/RewardProgram/DetailsRewardProgram";
import HoldOrderDetails from "../pages/Order/HoldCart/HoldOrderDetails";
import SyncInfo from "../pages/Setting/SyncInfo";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

const router = createBrowserRouter([
  {
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),

    children: [
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: "/",
        element: <DashBoard />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/attendance",
        element: <Attendance />,
      },
      {
        path: "/active-users",
        element: <ActiveUser />,
      },
      {
        path: "/inactive-users",
        element: <InActiveUser />,
      },
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/orders/details/:id",
        element: <OrderDetails />,
      },
      {
        path: "/hold-cart",
        element: <HoldCart />,
      },
      {
        path: "/hold-cart/details/:id",
        element: <HoldOrderDetails />,
      },
      {
        path: "/customer",
        element: <Customer />,
      },
      {
        path: "/remote-order",
        element: <RemoteOrder />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/sales-report",
        element: <SalesReport />,
      },
      {
        path: "/quick-sales-report",
        element: <QuickSalesReport />,
      },
      {
        path: "/userwise-report",
        element: <UserWiseReport />,
      },
      {
        path: "/productwise-report",
        element: <ProductWiseReport />,
      },
      {
        path: "/tablewise-report",
        element: <TableWiseReport />,
      },
      {
        path: "/floorwise-report",
        element: <FloorWiseReport />,
      },
      {
        path: "/categorywise-report",
        element: <CaregoryWiseReport />,
      },

      {
        path: "/detail-sales-report",
        element: <DetailSalesReport />,
      },
      {
        path: "/hourly-sales-report",
        element: <HourlySalesReport />,
      },
      {
        path: "/refund",
        element: <RefundExpenses />,
      },
      {
        path: "/refund/:id",
        element: <DetailsRefundExpenses />,
      },
      {
        path: "/cashout",
        element: <CashoutExpenses />,
      },
      {
        path: "/discount-expenses",
        element: <DiscountExpenses />,
      },
      {
        path: "/discount-expenses/:id",
        element: <DetailsDiscount />,
      },
      {
        path: "/void-order-expenses",
        element: <VoidExpenses />,
      },
      {
        path: "/reward-programs",
        element: <RewardProgramExpenses />,
      },
      {
        path: "/reward-programs/:id",
        element: <DetailsRewardProgram />,
      },
      {
        path: "/sync-info",
        element: <SyncInfo />,
      },
    ],
  },
]);

export default router;
