import React from "react";

const OrderSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.1364 6.30712H13.9996L10.2082 0.397985C9.86274 -0.132662 9.11142 -0.132662 8.77456 0.397985L4.98319 6.30712H0.863641C0.388637 6.30712 0 6.71181 0 7.20647C0 7.28746 0.00863921 7.36836 0.0345483 7.44935L2.22819 15.7868C2.42683 16.5423 3.09183 17.1 3.88637 17.1H15.1136C15.9082 17.1 16.5732 16.5423 16.7805 15.7868L17.8773 11.6181L18.9741 7.44935L19 7.20647C19 6.71181 18.6114 6.30712 18.1364 6.30712ZM9.49139 2.52059L11.9182 6.30712H7.06455L9.49139 2.52059ZM9.50001 13.5023C8.55004 13.5023 7.77276 12.6929 7.77276 11.7036C7.77276 10.7142 8.55004 9.90469 9.50001 9.90469C10.45 9.90469 11.2273 10.7142 11.2273 11.7036C11.2273 12.6929 10.45 13.5023 9.50001 13.5023Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.1364 6.30712H13.9996L10.2082 0.397985C9.86274 -0.132662 9.11142 -0.132662 8.77456 0.397985L4.98319 6.30712H0.863641C0.388637 6.30712 0 6.71181 0 7.20647C0 7.28746 0.00863921 7.36836 0.0345483 7.44935L2.22819 15.7868C2.42683 16.5423 3.09183 17.1 3.88637 17.1H15.1136C15.9082 17.1 16.5732 16.5423 16.7805 15.7868L17.8773 11.6181L18.9741 7.44935L19 7.20647C19 6.71181 18.6114 6.30712 18.1364 6.30712ZM9.49139 2.52059L11.9182 6.30712H7.06455L9.49139 2.52059ZM9.50001 13.5023C8.55004 13.5023 7.77276 12.6929 7.77276 11.7036C7.77276 10.7142 8.55004 9.90469 9.50001 9.90469C10.45 9.90469 11.2273 10.7142 11.2273 11.7036C11.2273 12.6929 10.45 13.5023 9.50001 13.5023Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default OrderSVG;
