import React from "react";

const CustomerSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.75 7C6.61195 7 7.43864 6.6576 8.04808 6.04808C8.6576 5.43864 9 4.61195 9 3.75C9 2.88805 8.6576 2.0614 8.04808 1.4519C7.43864 0.842408 6.61195 0.5 5.75 0.5C4.88805 0.5 4.0614 0.842408 3.4519 1.4519C2.84241 2.0614 2.5 2.88805 2.5 3.75C2.5 4.61195 2.84241 5.43864 3.4519 6.04808C4.0614 6.6576 4.88805 7 5.75 7ZM11.437 12.145C11.967 12.362 12.641 12.5 13.499 12.5C17.499 12.5 17.499 9.5 17.499 9.5C17.499 9.10232 17.3411 8.72096 17.06 8.43968C16.779 8.1584 16.3977 8.00024 16 8H11.372C11.765 8.476 12.001 9.08496 12.001 9.75V10.106C11.999 10.1902 11.9933 10.2743 11.984 10.358C11.9166 10.9828 11.7313 11.5892 11.438 12.145H11.437ZM16 4.5C16 5.16304 15.7366 5.79896 15.2678 6.26776C14.799 6.73664 14.163 7 13.5 7C12.837 7 12.201 6.73664 11.7322 6.26776C11.2634 5.79896 11 5.16304 11 4.5C11 3.83696 11.2634 3.20107 11.7322 2.73223C12.201 2.26339 12.837 2 13.5 2C14.163 2 14.799 2.26339 15.2678 2.73223C15.7366 3.20107 16 3.83696 16 4.5ZM0.5 10C0.5 9.4696 0.710712 8.96088 1.08578 8.58576C1.46086 8.21072 1.96957 8 2.5 8H9C9.5304 8 10.0391 8.21072 10.4142 8.58576C10.7893 8.96088 11 9.4696 11 10C11 10 11 14 5.75 14C0.5 14 0.5 10 0.5 10ZM12 10.106L11.997 10.17L11.999 10.106H12Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.75 7C6.61195 7 7.43864 6.6576 8.04808 6.04808C8.6576 5.43864 9 4.61195 9 3.75C9 2.88805 8.6576 2.0614 8.04808 1.4519C7.43864 0.842408 6.61195 0.5 5.75 0.5C4.88805 0.5 4.0614 0.842408 3.4519 1.4519C2.84241 2.0614 2.5 2.88805 2.5 3.75C2.5 4.61195 2.84241 5.43864 3.4519 6.04808C4.0614 6.6576 4.88805 7 5.75 7ZM11.437 12.145C11.967 12.362 12.641 12.5 13.499 12.5C17.499 12.5 17.499 9.5 17.499 9.5C17.499 9.10232 17.3411 8.72096 17.06 8.43968C16.779 8.1584 16.3977 8.00024 16 8H11.372C11.765 8.476 12.001 9.08496 12.001 9.75V10.106C11.999 10.1902 11.9933 10.2743 11.984 10.358C11.9166 10.9828 11.7313 11.5892 11.438 12.145H11.437ZM16 4.5C16 5.16304 15.7366 5.79896 15.2678 6.26776C14.799 6.73664 14.163 7 13.5 7C12.837 7 12.201 6.73664 11.7322 6.26776C11.2634 5.79896 11 5.16304 11 4.5C11 3.83696 11.2634 3.20107 11.7322 2.73223C12.201 2.26339 12.837 2 13.5 2C14.163 2 14.799 2.26339 15.2678 2.73223C15.7366 3.20107 16 3.83696 16 4.5ZM0.5 10C0.5 9.4696 0.710712 8.96088 1.08578 8.58576C1.46086 8.21072 1.96957 8 2.5 8H9C9.5304 8 10.0391 8.21072 10.4142 8.58576C10.7893 8.96088 11 9.4696 11 10C11 10 11 14 5.75 14C0.5 14 0.5 10 0.5 10ZM12 10.106L11.997 10.17L11.999 10.106H12Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default CustomerSVG;
