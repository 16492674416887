import React from "react";

const HoldCartSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83325 14.0001C4.91658 14.0001 4.17492 14.7501 4.17492 15.6667C4.17492 16.5834 4.91658 17.3334 5.83325 17.3334C6.74992 17.3334 7.49992 16.5834 7.49992 15.6667C7.49992 14.7501 6.74992 14.0001 5.83325 14.0001ZM0.833252 1.50008C0.833252 1.95842 1.20825 2.33342 1.66659 2.33342H2.49992L5.49992 8.65841L4.37492 10.6917C3.76659 11.8084 4.56659 13.1667 5.83325 13.1667H14.9999C15.4583 13.1667 15.8333 12.7917 15.8333 12.3334C15.8333 11.8751 15.4583 11.5001 14.9999 11.5001H5.83325L6.74992 9.8334H12.9583C13.5833 9.8334 14.1333 9.4917 14.4166 8.97508L17.3999 3.56675C17.4701 3.44044 17.5062 3.29797 17.5044 3.15346C17.5027 3.00895 17.4633 2.86739 17.39 2.7428C17.3168 2.61821 17.2123 2.5149 17.0869 2.4431C16.9614 2.37129 16.8194 2.33349 16.6749 2.33342H4.34159L3.78325 1.14175C3.71628 0.999499 3.61017 0.879279 3.47734 0.795159C3.34452 0.711039 3.19047 0.666499 3.03325 0.666749H1.66659C1.20825 0.666749 0.833252 1.04175 0.833252 1.50008ZM14.1666 14.0001C13.2499 14.0001 12.5083 14.7501 12.5083 15.6667C12.5083 16.5834 13.2499 17.3334 14.1666 17.3334C15.0833 17.3334 15.8333 16.5834 15.8333 15.6667C15.8333 14.7501 15.0833 14.0001 14.1666 14.0001Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83325 14.0001C4.91658 14.0001 4.17492 14.7501 4.17492 15.6667C4.17492 16.5834 4.91658 17.3334 5.83325 17.3334C6.74992 17.3334 7.49992 16.5834 7.49992 15.6667C7.49992 14.7501 6.74992 14.0001 5.83325 14.0001ZM0.833252 1.50008C0.833252 1.95842 1.20825 2.33342 1.66659 2.33342H2.49992L5.49992 8.65841L4.37492 10.6917C3.76659 11.8084 4.56659 13.1667 5.83325 13.1667H14.9999C15.4583 13.1667 15.8333 12.7917 15.8333 12.3334C15.8333 11.8751 15.4583 11.5001 14.9999 11.5001H5.83325L6.74992 9.8334H12.9583C13.5833 9.8334 14.1333 9.4917 14.4166 8.97508L17.3999 3.56675C17.4701 3.44044 17.5062 3.29797 17.5044 3.15346C17.5027 3.00895 17.4633 2.86739 17.39 2.7428C17.3168 2.61821 17.2123 2.5149 17.0869 2.4431C16.9614 2.37129 16.8194 2.33349 16.6749 2.33342H4.34159L3.78325 1.14175C3.71628 0.999499 3.61017 0.879279 3.47734 0.795159C3.34452 0.711039 3.19047 0.666499 3.03325 0.666749H1.66659C1.20825 0.666749 0.833252 1.04175 0.833252 1.50008ZM14.1666 14.0001C13.2499 14.0001 12.5083 14.7501 12.5083 15.6667C12.5083 16.5834 13.2499 17.3334 14.1666 17.3334C15.0833 17.3334 15.8333 16.5834 15.8333 15.6667C15.8333 14.7501 15.0833 14.0001 14.1666 14.0001Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default HoldCartSVG;
