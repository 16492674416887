import React from "react";
import CardLoader from "./CardLoader";
import SalesLoader from "./SalesLoader";
import OrderLoader from "./OrderLoader";
import ExpensesLoader from "./ExpensesLoader";
type Props = {};

const SalesLoaderLayout = (props: Props) => {
  return (
    <div className="p-3">
      <CardLoader />
      <SalesLoader />
      <OrderLoader />
      <ExpensesLoader />
      <CardLoader />
    </div>
  );
};

export default SalesLoaderLayout;
