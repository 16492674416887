import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <h1 className="font-medium text-lg">Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
