import React, { useEffect, useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner/index";
import {
  QUERY_SETTINGS,
  QUERY_TRANSACTION_ACCOUNTS,
  QUICK_SALE_REPORT,
} from "../../query";
import moment from "moment";

import { toast } from "react-toastify";
import { useLazyQuery, useQuery } from "@apollo/client";

const QuickSalesReportComponent: any = Loadable({
  loader: () => import("../../../components/Reports/QuickSalesReport"),
  loading: DataLoadSpinner,
});
type Props = {};

const QuickSalesReport = (props: Props) => {
  const [transactionAccountDropdown, setTransactionAccountDropdown] =
    useState<any>([]);
  const [reportOpenState, setReportOpenState] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>();

  const { data: settingsData } = useQuery(QUERY_SETTINGS);

  // TRANSACTION ACCOUNT DROPDOWN
  const { data: transactionAccount } = useQuery(QUERY_TRANSACTION_ACCOUNTS, {
    variables: {
      isVisible: true,
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    transactionAccount?.transactionAccounts?.edges?.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    );
    setTransactionAccountDropdown(dropdownFormat);
  }, [transactionAccount]);

  //generate quick sales report
  const [generateDetailSalesReport, { loading: quickSalesReportLoading }] =
    useLazyQuery(QUICK_SALE_REPORT, {
      fetchPolicy: "no-cache",
    });
  const initaltransactionAccount = transactionAccountDropdown?.map(
    (item: any) => item.id
  );

  const handleGenerateQuickSalesReport = async (values: any, modeOfPayment: any) => {
    const response = await generateDetailSalesReport({
      variables: {
        paymentMethods: modeOfPayment ?? initaltransactionAccount,
        taxType: values.taxType ?? [],
        fromDateTime: moment(values.fromDate).format('yyyy-MM-DDTHH:mm:ss'),
        toDateTime: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
      },
    });
    if (response?.data?.quickSaleReport?.createdOn) {
      setReportOpenState(true);
      setReportData(response?.data?.quickSaleReport);
    } else {
      toast.error("Report data available");
    }
  };
  const handleReset = (setFieldValue: any) => {
    setFieldValue('modeOfPayment', [])
    setFieldValue('isTaxable', [])
    setFieldValue('fromDate', "")
    setFieldValue('toDate', "")
  }
  return (
    <div>
      <QuickSalesReportComponent
        handleGenerateQuickSalesReport={handleGenerateQuickSalesReport}
        quickSalesReportLoading={quickSalesReportLoading}
        transactionAccountDropdown={transactionAccountDropdown}
        reportData={reportData}
        handleReset={handleReset}
        reportOpenState={reportOpenState}
        setReportOpenState={setReportOpenState}
        currency={settingsData?.basicWebsiteConfigurations?.currency}
      />
    </div>
  );
};
export default QuickSalesReport;
