import "./app.css";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import Router from "./routers";
import apolloClient from "./apolloClient";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
export default function Root(props) {
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={Router} />
          {/* <ToastContainer autoClose={1000}/> */}
        </QueryClientProvider>
      </ApolloProvider>
    </>
  );
}
