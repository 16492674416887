import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import IconButton from "../Reusable/Button/IconButton/IconButton";
import CloseIcon from "../../assets/icon/closeicon.svg";
import { Disclosure } from "@headlessui/react";
import routerObject from "../../routers/router";
import { classNames } from "../../utils/classNames";
import { useGetHook } from "../../customhooks/useApiData";

type Props = {
  isOpenSidebar: boolean;
  setIsOpenSidebar: any;
};

const RenderIcon = ({ Icon, isActive }: any) => {
  return <Icon isActive={isActive} />;
};

const Sidebar = (props: Props) => {
  const { isOpenSidebar, setIsOpenSidebar } = props;
  const [openDisclosure, setOpenDisclosure] = useState<number | null>(null);

  const handleDisclosureChange = (index: number, open: any) => {
    setOpenDisclosure(openDisclosure === index ? null : index);
  };
  const { data, isLoading: userLoading } = useGetHook({
    url: "/user/me",
    queryKey: ["user/me"],
    params: {},
  });
  const location = useLocation();
  return (
    <div
      className={`w-5/6 h-full shadow bg-white z-50 overflow-y-auto transition-transform ease-in-out duration-500 fixed top-0 left-0 bottom-0 px-3 pt-3 ${
        isOpenSidebar ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex justify-between p-3 border-b">
            <Link
              to={"/profile"}
              className="flex gap-4"
              onClick={() => setIsOpenSidebar(!isOpenSidebar)}
            >
              <span className="bg-gray-300 rounded-full p-3">DD</span>
              <div className="my-auto">
                <p className="capitalize">{data?.full_name}</p>
              </div>
            </Link>
            <IconButton
              icon={CloseIcon}
              onClick={() => setIsOpenSidebar(!isOpenSidebar)}
            />
          </div>
          <nav className="flex-1 px-2 my-3">
            {routerObject?.map((item: any, index: number) =>
              item?.children?.length ? (
                <Disclosure key={index} defaultOpen={openDisclosure === index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={classNames(
                          item.path === window.location.pathname
                            ? "bg-indigo-800 text-white"
                            : "text-black",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded w-full my-1"
                        )}
                        onClick={() => handleDisclosureChange(index, open)}
                      >
                        <img src={item?.icon} alt="" className="w-5 h-5 mr-2" />
                        {item?.name}
                      </Disclosure.Button>
                      <Disclosure.Panel className="ml-4">
                        {item?.children?.map((elem: any) => (
                          <NavLink
                            key={elem.name}
                            to={elem.path}
                            className={({ isActive, isPending }) => {
                              return `${
                                isActive
                                  ? "bg-[#5b6ab0] text-white"
                                  : isPending
                                  ? "text-indigo-100 hover:bg-indigo-800"
                                  : ""
                              } group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
                            }}
                            onClick={() => setIsOpenSidebar(!isOpenSidebar)}
                          >
                            <RenderIcon
                              Icon={elem?.icon}
                              isActive={
                                elem?.path === window.location.pathname
                                  ? true
                                  : false
                              }
                            />
                            {elem?.name}
                          </NavLink>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ) : (
                <NavLink
                  key={item.name}
                  to={item.path}
                  className={({ isActive, isPending }) => {
                    return `${
                      isActive
                        ? "bg-[#5b6ab0] text-white"
                        : isPending
                        ? "text-indigo-100 hover:bg-indigo-600"
                        : ""
                    } group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
                  }}
                  onClick={() => setIsOpenSidebar(!isOpenSidebar)}
                >
                  <RenderIcon
                    Icon={item?.icon}
                    isActive={
                      item?.path === window.location.pathname ? true : false
                    }
                  />
                  {/* <img src={item?.icon} alt="" className="w-4 h-4 mr-2" /> */}
                  {item.name}
                </NavLink>
              )
            )}
          </nav>
        </div>
        <div className=" w-full text-center bg-white py-3 border-t text-sm ">
          Powered by:
          <a
            href="https://www.dibtech.com.au/"
            className="text-white bg-indigo-600 px-2 py-1 italic font-semibold rounded-tl-md rounded-br-md"
          >
            Dibtech Business Solution
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
