import React from "react";

const SalesReportSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.35995 2.15237H4.27995V0.799988H8.35995V2.15237ZM7.81595 9.25237V10.0908C7.33995 10.4966 6.99995 11.1051 6.99995 11.7543V14.1209C6.99995 14.4049 7.06115 14.6822 7.16315 14.9391C6.88435 14.9729 6.60555 15 6.31995 15C4.69683 15 3.14017 14.3588 1.99245 13.2175C0.844731 12.0762 0.199951 10.5283 0.199951 8.91427C0.199951 7.30025 0.844731 5.75231 1.99245 4.61103C3.14017 3.46973 4.69683 2.82857 6.31995 2.82857C7.76155 2.82857 9.08755 3.32895 10.1416 4.18095L11.1072 3.20723C11.454 3.50475 11.76 3.81579 12.066 4.16065L11.1004 5.12085C11.3928 5.48599 11.6376 5.87141 11.8416 6.30419C11.5968 6.25009 11.3452 6.20951 11.08 6.20951C9.31195 6.20951 7.81595 7.60247 7.81595 9.25237ZM6.99995 4.85713H5.63995V9.59047H6.99995V4.85713ZM12.984 10.9428H10.06V9.25237C10.06 8.71141 10.536 8.37333 11.08 8.37333C11.624 8.37333 12.1 8.71141 12.1 9.25237V9.59047H12.984V9.25237C12.984 8.30571 12.032 7.56189 11.08 7.56189C10.128 7.56189 9.17595 8.30571 9.17595 9.25237V10.9428C8.76795 10.9428 8.35995 11.3486 8.35995 11.7543V14.1209C8.35995 14.5943 8.76795 15 9.17595 15H12.916C13.392 15 13.8 14.5943 13.8 14.1886V11.8219C13.8 11.3486 13.392 10.9428 12.984 10.9428Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.35995 2.15237H4.27995V0.799988H8.35995V2.15237ZM7.81595 9.25237V10.0908C7.33995 10.4966 6.99995 11.1051 6.99995 11.7543V14.1209C6.99995 14.4049 7.06115 14.6822 7.16315 14.9391C6.88435 14.9729 6.60555 15 6.31995 15C4.69683 15 3.14017 14.3588 1.99245 13.2175C0.844731 12.0762 0.199951 10.5283 0.199951 8.91427C0.199951 7.30025 0.844731 5.75231 1.99245 4.61103C3.14017 3.46973 4.69683 2.82857 6.31995 2.82857C7.76155 2.82857 9.08755 3.32895 10.1416 4.18095L11.1072 3.20723C11.454 3.50475 11.76 3.81579 12.066 4.16065L11.1004 5.12085C11.3928 5.48599 11.6376 5.87141 11.8416 6.30419C11.5968 6.25009 11.3452 6.20951 11.08 6.20951C9.31195 6.20951 7.81595 7.60247 7.81595 9.25237ZM6.99995 4.85713H5.63995V9.59047H6.99995V4.85713ZM12.984 10.9428H10.06V9.25237C10.06 8.71141 10.536 8.37333 11.08 8.37333C11.624 8.37333 12.1 8.71141 12.1 9.25237V9.59047H12.984V9.25237C12.984 8.30571 12.032 7.56189 11.08 7.56189C10.128 7.56189 9.17595 8.30571 9.17595 9.25237V10.9428C8.76795 10.9428 8.35995 11.3486 8.35995 11.7543V14.1209C8.35995 14.5943 8.76795 15 9.17595 15H12.916C13.392 15 13.8 14.5943 13.8 14.1886V11.8219C13.8 11.3486 13.392 10.9428 12.984 10.9428Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default SalesReportSVG;
