import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner";
type Props = {};

const DashBoardComponent: any = Loadable({
  loader: () => import("../../components/DashBoard/DashBoard"),
  loading: DataLoadSpinner,
});
const DashBoard = (props: Props) => {

  return (
    <>
      <DashBoardComponent />
    </>
  );
};

export default DashBoard;
