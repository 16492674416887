import React from "react";

const SettingSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4333 9.82508L14.425 9.84174C14.4583 9.56674 14.4917 9.28342 14.4917 9.00008C14.4917 8.71674 14.4667 8.45008 14.4333 8.17508L14.4417 8.19174L16.475 6.59174L14.45 3.07508L12.0583 4.04174L12.0667 4.05008C11.6333 3.71674 11.1583 3.43342 10.6417 3.21674H10.65L10.2667 0.666748H6.20833L5.84167 3.22508H5.85001C5.33333 3.44174 4.85833 3.72508 4.42501 4.05842L4.43333 4.05008L2.03333 3.07508L0 6.59174L2.03333 8.19174L2.04167 8.17508C2.00833 8.45008 1.98333 8.71674 1.98333 9.00008C1.98333 9.28342 2.00833 9.56674 2.05001 9.84174L2.04167 9.82508L0.29167 11.2001L0.0166679 11.4167L2.04167 14.9167L4.44167 13.9584L4.42501 13.9251C4.86667 14.2667 5.34167 14.5501 5.86667 14.7667H5.84167L6.21667 17.3334H10.2583C10.2583 17.3334 10.2833 17.1834 10.3083 16.9834L10.625 14.7751H10.6167C11.1333 14.5584 11.6167 14.2751 12.0583 13.9334L12.0417 13.9667L14.4417 14.9251L16.4667 11.4251C16.4667 11.4251 16.35 11.3251 16.1917 11.2084L14.4333 9.82508ZM8.23333 11.9167C6.62501 11.9167 5.31667 10.6084 5.31667 9.00008C5.31667 7.39174 6.62501 6.08342 8.23333 6.08342C9.84167 6.08342 11.15 7.39174 11.15 9.00008C11.15 10.6084 9.84167 11.9167 8.23333 11.9167Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4333 9.82508L14.425 9.84174C14.4583 9.56674 14.4917 9.28342 14.4917 9.00008C14.4917 8.71674 14.4667 8.45008 14.4333 8.17508L14.4417 8.19174L16.475 6.59174L14.45 3.07508L12.0583 4.04174L12.0667 4.05008C11.6333 3.71674 11.1583 3.43342 10.6417 3.21674H10.65L10.2667 0.666748H6.20833L5.84167 3.22508H5.85001C5.33333 3.44174 4.85833 3.72508 4.42501 4.05842L4.43333 4.05008L2.03333 3.07508L0 6.59174L2.03333 8.19174L2.04167 8.17508C2.00833 8.45008 1.98333 8.71674 1.98333 9.00008C1.98333 9.28342 2.00833 9.56674 2.05001 9.84174L2.04167 9.82508L0.29167 11.2001L0.0166679 11.4167L2.04167 14.9167L4.44167 13.9584L4.42501 13.9251C4.86667 14.2667 5.34167 14.5501 5.86667 14.7667H5.84167L6.21667 17.3334H10.2583C10.2583 17.3334 10.2833 17.1834 10.3083 16.9834L10.625 14.7751H10.6167C11.1333 14.5584 11.6167 14.2751 12.0583 13.9334L12.0417 13.9667L14.4417 14.9251L16.4667 11.4251C16.4667 11.4251 16.35 11.3251 16.1917 11.2084L14.4333 9.82508ZM8.23333 11.9167C6.62501 11.9167 5.31667 10.6084 5.31667 9.00008C5.31667 7.39174 6.62501 6.08342 8.23333 6.08342C9.84167 6.08342 11.15 7.39174 11.15 9.00008C11.15 10.6084 9.84167 11.9167 8.23333 11.9167Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default SettingSVG;
