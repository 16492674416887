import React from "react";

const TransactionsSVG = (isActive: any) => {
  return (
    <>
      {isActive?.isActive ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 0.875C4.5125 0.875 0.875 4.5125 0.875 9C0.875 13.4875 4.5125 17.125 9 17.125C13.4875 17.125 17.125 13.4875 17.125 9C17.125 4.5125 13.4875 0.875 9 0.875ZM9.62496 4C9.62496 3.83424 9.55912 3.67527 9.44192 3.55806C9.32472 3.44085 9.16576 3.375 9 3.375C8.83424 3.375 8.67528 3.44085 8.55808 3.55806C8.44088 3.67527 8.37504 3.83424 8.37504 4V4.68C7.8524 4.76794 7.35984 4.98443 6.94166 5.31C6.34834 5.78166 6.015 6.435 6.015 7.12496C6.015 7.81584 6.34834 8.46832 6.9425 8.94C7.3608 9.27336 7.8608 9.4792 8.37504 9.57V12.0517C8.09816 11.9875 7.83712 11.8682 7.60752 11.7008L6.875 11.1508C6.80934 11.1016 6.73462 11.0658 6.65511 11.0454C6.57561 11.025 6.49286 11.0205 6.41161 11.0321C6.24752 11.0555 6.09946 11.1432 6 11.2758C5.90054 11.4085 5.85784 11.5751 5.88128 11.7392C5.90472 11.9033 5.99239 12.0514 6.125 12.1508L6.8575 12.7008C7.30168 13.0342 7.83168 13.2383 8.37504 13.3258V14C8.37504 14.1658 8.44088 14.3247 8.55808 14.4419C8.67528 14.5591 8.83424 14.625 9 14.625C9.16576 14.625 9.32472 14.5591 9.44192 14.4419C9.55912 14.3247 9.62496 14.1658 9.62496 14V13.325C10.1738 13.2426 10.6945 13.0284 11.1425 12.7008C11.7634 12.235 12.125 11.5808 12.125 10.875C12.125 10.1692 11.7634 9.51496 11.1425 9.0492C10.6946 8.72128 10.1739 8.5068 9.62496 8.42416V5.96166C9.86664 6.03 10.0908 6.13917 10.28 6.28917L10.6258 6.56417C10.7557 6.66727 10.9212 6.71457 11.0859 6.69566C11.2506 6.67675 11.401 6.59318 11.5042 6.46334C11.6073 6.33349 11.6546 6.168 11.6357 6.00328C11.6167 5.83856 11.5332 5.6881 11.4034 5.585L11.0575 5.31C10.6395 4.98486 10.1473 4.76866 9.62496 4.68083V4Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 0.875C4.5125 0.875 0.875 4.5125 0.875 9C0.875 13.4875 4.5125 17.125 9 17.125C13.4875 17.125 17.125 13.4875 17.125 9C17.125 4.5125 13.4875 0.875 9 0.875ZM9.62496 4C9.62496 3.83424 9.55912 3.67527 9.44192 3.55806C9.32472 3.44085 9.16576 3.375 9 3.375C8.83424 3.375 8.67528 3.44085 8.55808 3.55806C8.44088 3.67527 8.37504 3.83424 8.37504 4V4.68C7.8524 4.76794 7.35984 4.98443 6.94166 5.31C6.34834 5.78166 6.015 6.435 6.015 7.12496C6.015 7.81584 6.34834 8.46832 6.9425 8.94C7.3608 9.27336 7.8608 9.4792 8.37504 9.57V12.0517C8.09816 11.9875 7.83712 11.8682 7.60752 11.7008L6.875 11.1508C6.80934 11.1016 6.73462 11.0658 6.65511 11.0454C6.57561 11.025 6.49286 11.0205 6.41161 11.0321C6.24752 11.0555 6.09946 11.1432 6 11.2758C5.90054 11.4085 5.85784 11.5751 5.88128 11.7392C5.90472 11.9033 5.99239 12.0514 6.125 12.1508L6.8575 12.7008C7.30168 13.0342 7.83168 13.2383 8.37504 13.3258V14C8.37504 14.1658 8.44088 14.3247 8.55808 14.4419C8.67528 14.5591 8.83424 14.625 9 14.625C9.16576 14.625 9.32472 14.5591 9.44192 14.4419C9.55912 14.3247 9.62496 14.1658 9.62496 14V13.325C10.1738 13.2426 10.6945 13.0284 11.1425 12.7008C11.7634 12.235 12.125 11.5808 12.125 10.875C12.125 10.1692 11.7634 9.51496 11.1425 9.0492C10.6946 8.72128 10.1739 8.5068 9.62496 8.42416V5.96166C9.86664 6.03 10.0908 6.13917 10.28 6.28917L10.6258 6.56417C10.7557 6.66727 10.9212 6.71457 11.0859 6.69566C11.2506 6.67675 11.401 6.59318 11.5042 6.46334C11.6073 6.33349 11.6546 6.168 11.6357 6.00328C11.6167 5.83856 11.5332 5.6881 11.4034 5.585L11.0575 5.31C10.6395 4.98486 10.1473 4.76866 9.62496 4.68083V4Z"
            fill="#14191E"
          />
        </svg>
      )}
    </>
  );
};

export default TransactionsSVG;
