import React, { useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner/index";
import { HOURLY_SALES_REPORT, QUERY_SETTINGS } from "../../query";
import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";

const HourlySalesReportComponent: any = Loadable({
  loader: () => import("../../../components/Reports/HourlySalesReport"),
  loading: DataLoadSpinner,
});
type Props = {};

const HourlySalesReport = (props: Props) => {
  const [reportOpenState, setReportOpenState] = useState<boolean>(false);
  const [reportData, setReportData] = useState<any>();
  const { data: settingsData } = useQuery(QUERY_SETTINGS);
  //generate hour sales report
  const [generateDetailSalesReport, { loading: hourSalesReportLoading }] =
    useLazyQuery(HOURLY_SALES_REPORT, {
      fetchPolicy: "no-cache",
    });

  const handleGenerateHourSalesReport = async (values: any) => {
    const response = await generateDetailSalesReport({
      variables: {
        fromDate: moment(values.startDate).format("yyyy-MM-DD"),
        toDate: moment(values.endDate).format("yyyy-MM-DD"),
      },
    });
    if (response?.data?.hourlyTransactionHistory?.date) {
      setReportOpenState(true);
      setReportData(response?.data?.hourlyTransactionHistory);
    } else {
      toast.error("Report data available");
    }
  };

  return (
    <>
      <HourlySalesReportComponent
        handleGenerateHourSalesReport={handleGenerateHourSalesReport}
        hourSalesReportLoading={hourSalesReportLoading}
        reportData={reportData}
        reportOpenState={reportOpenState}
        setReportOpenState={setReportOpenState}
        currency={settingsData?.basicWebsiteConfigurations?.currency}
      />
    </>
  );
};

export default HourlySalesReport;
