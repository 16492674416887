import React, { useState } from "react";
import Loadable from "react-loadable";
import usePaginationState from "../../../customhooks/usePaginationHook";
import moment from "moment";
import { CATEGORYWISE_REPORT } from "../../query";
import { useQuery, useReactiveVar } from "@apollo/client";
import { selectedDatePickerItemVar } from "../../../components/LocalState/Cache";
import TableLoader from "../../../components/Reusable/Loader/TableLoader";

const CategoryWiseReportComponent: any = Loadable({
  loader: () => import("../../../components/Reports/CategoryWiseReport"),
  loading: () => <TableLoader />,
});
type Props = {};

const CaregoryWiseReport = (props: Props) => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();
  const { startDate, endDate }: any = useReactiveVar(selectedDatePickerItemVar);

  const { data, loading } = useQuery(CATEGORYWISE_REPORT, {
    variables: {
      fromDateTime: startDate
        ? moment(startDate).startOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : moment(new Date()).startOf("day").format("yyyy-MM-DDTHH:mm:ss"),
      toDateTime: endDate
        ? moment(endDate).endOf("day").format("yyyy-MM-DDTHH:mm:ss")
        : moment(new Date()).endOf("day").format("yyyy-MM-DDTHH:mm:ss"),
      terminal: [],
    },
  });
  return (
    <div>
      <CategoryWiseReportComponent
        offset={offset}
        setOffset={setOffset}
        pageSize={pageSize}
        setPageSize={setPageSize}
        data={data?.detailCategoriesWiseReport}
        loading={loading}
      />
    </div>
  );
};

export default CaregoryWiseReport;
