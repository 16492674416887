import Loadable from "react-loadable";
import DataLoadSpinner from "../../components/Reusable/Spinner";
import { useGetHook, usePostHook } from "../../customhooks/useApiData";
import { useReactiveVar } from "@apollo/client";
import { selectedDatePickerItemVar } from "../../components/LocalState/Cache";
import { useEffect } from "react";
import TableLoader from "../../components/Reusable/Loader/TableLoader";
type Props = {};
const AttendanceComponent: any = Loadable({
  loader: () => import("../../components/Attendance/Attendance"),
  loading: () => <TableLoader showBar={false} />,
});

const Attendance = (props: Props) => {
  const {
    mutate,
    isLoading: attendanceLoading,
    data: attendanceData,
  } = usePostHook();
  const { startDate, endDate }: any = useReactiveVar(selectedDatePickerItemVar);
  useEffect(() => {
    mutate({
      url: "attendance/",
      formData: {
        start_date: startDate ?? new Date().toISOString(),
        end_date: endDate ?? new Date().toISOString(),
      },
    });
  }, [startDate, endDate]);

  return (
    <div>
      <AttendanceComponent
        data={attendanceData}
        attendanceLoading={attendanceLoading}
      />
    </div>
  );
};

export default Attendance;
