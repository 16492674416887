import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const SalesLoader = (props: Props) => {
  const {} = props;

  return (
    <div className="relative rounded bg-white shadow p-4 mt-4">
      <div className="flex justify-between">
        <div className=" mb-4">
          <Skeleton height={30} width={60} />
        </div>
        <div className="flex justify-between gap-4">
          <Skeleton height={30} width={60} />
          <Skeleton height={30} width={60} />
        </div>
      </div>

      <div className="h-64 col-span-3 mx-auto" style={{ width: "100%" }}>
        <Skeleton className="w-full h-full" />
      </div>
      <div className="w-full grid grid-cols-2 gap-2 mt-3">
        <Skeleton height={90} />
        <Skeleton height={90}  />
      </div>
    </div>
  );
};

export default SalesLoader;
