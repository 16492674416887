import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {};

const ExpensesLoader = (props: Props) => {
  return (
    <>
      <div className="relative rounded bg-white shadow p-4 my-4">
        <div className="flex justify-between ">
          <h2 className="text-xl font-semibold mb-4">
            <Skeleton height={30} width={60} />
          </h2>
          <div className="flex justify-between gap-4">
            <Skeleton height={30} width={60} />
            <Skeleton height={30} width={60} />
          </div>
        </div>
        <div className="h-64 w-full mx-auto">
          <Skeleton className="my-auto w-full h-full" />
        </div>
        <div className="grid grid-cols-2 gap-3 mt-3">
          <Skeleton height={90} />
          <Skeleton height={90} />
          <Skeleton height={90} />
          <Skeleton height={90} />
        </div>
      </div>
    </>
  );
};

export default ExpensesLoader;
