import React from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/Reusable/Spinner";
import { useParams } from "react-router-dom";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { QUERY_ORDERED_ITEMS } from "../../query";
import { useQuery } from "@apollo/client";
import DetailPageLoader from "../../../components/Reusable/Loader/DetailPageLoader";
type Props = {};
const DetailsDiscountExpensesComponent: any = Loadable({
  loader: () =>
    import("../../../components/ExpensesReport/Discount/DetailsDiscount"),
  loading: () => <DetailPageLoader count={17} />,
});
const DetailsDiscount = (props: Props) => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY_ORDERED_ITEMS, {
    variables: {
      id: id,
      offset: offset,
      first: pageSize,
    },
  });
  const pageInfo = data?.order?.orderItems?.pageInfo ?? {};
  return (
    <div>
      <DetailsDiscountExpensesComponent
        data={data?.order}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
        loading={loading}
        pageInfo={pageInfo}
      />
    </div>
  );
};

export default DetailsDiscount;
