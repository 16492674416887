import { useState } from "react";

const usePaginationState = () => {
  const [offset, setOffset] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(10);

  const handleNextPage = (endCursor: any, fetchMore: any) => {
    fetchMore({
      variables: { after: endCursor },
    });
  };
  const handlePreviousPage = (startCursor: any) => {
    // setNextPage("");
    // setPreviousPage(startCursor);
  };
  return {
    pageSize,
    setPageSize,
    offset,
    setOffset,
    handleNextPage,
    handlePreviousPage,
  };
};

export default usePaginationState;
